import { FC, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MESSAGE_TYPES } from '@/constants/websockets';

import { OpenChatIcon } from '@/components/ui/icons';

import { Badge } from './components';
import { ChatButtonWrapper, Wrapper, Text } from './styles';
import {
  useChatContext,
  useChatOpenedContext,
  useAuthContext,
} from '@/contexts';

interface OpenChatButtonProps {
  isOpen: boolean;
  onOpen: () => void;
}

export const OpenChatButton: FC<OpenChatButtonProps> = (props) => {
  const { onOpen, isOpen } = props;
  const [isOpenedBefore, setOpenedBefore] = useState(false);
  const { token } = useAuthContext();
  const { openedLot, openedAuction } = useChatOpenedContext();

  const { t } = useTranslation();
  const { sendMessage, isConnected } = useChatContext();

  const onInit = () => {
    onOpen();

    if (!isOpenedBefore) {
      console.log('get rooms');
      sendMessage(MESSAGE_TYPES.GET_ROOMS);
      setOpenedBefore(true);
    }
  };

  useEffect(() => {
    setOpenedBefore(false);
  }, [token]);

  useEffect(() => {
    if (openedLot || openedAuction) {
      onInit();
    }
  }, [openedLot, openedAuction]);

  return (
    <ChatButtonWrapper isVisible={!isOpen && isConnected}>
      <Badge />

      <Wrapper onClick={onInit}>
        <OpenChatIcon />

        <Text>{t('button.chat_button')}</Text>
      </Wrapper>
    </ChatButtonWrapper>
  );
};
