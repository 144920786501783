import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export const filterSelector = createSelector(
  (state: RootState) => state.filter,
  (filter) => filter
);

export const filterSearchSelector = createSelector(
  (state: RootState) => state.filter,
  (filter) => filter.search
);
