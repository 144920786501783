import { ChatContextProps, ChatContext } from './context';
import { useContext, FC } from 'react';
import { useValue } from './hook';
import { PropsWithChildren } from 'react';

export const ChatProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const value = useValue();

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => useContext<ChatContextProps>(ChatContext);
