export const ChatIcon = () => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_277_45323)">
        <path
          d="M24.7461 20.4965L24.7183 20.4909C22.1792 19.9883 20.628 19.3251 19.9065 18.9609C18.8442 19.2437 17.7494 19.3861 16.65 19.3846C10.52 19.3846 5.55 15.0452 5.55 9.69231C5.55 4.33939 10.52 0 16.65 0C22.78 0 27.75 4.33939 27.75 9.69231C27.75 12.1292 26.7191 14.3585 25.0166 16.0615C25.1357 17.1555 25.3797 18.2323 25.7437 19.2711L25.7478 19.2863C25.8513 19.5838 25.9642 19.878 26.0864 20.1683C26.196 20.4258 25.9837 20.7138 25.7076 20.6695C25.3862 20.617 25.0656 20.5593 24.7461 20.4965ZM23.6361 16.1931C23.6166 15.9904 23.6422 15.7859 23.7111 15.5942C23.7799 15.4025 23.8903 15.2283 24.0343 15.084C25.505 13.6108 26.3625 11.7249 26.3625 9.69231C26.3625 5.27262 22.1944 1.38462 16.65 1.38462C11.1055 1.38462 6.9375 5.27262 6.9375 9.69231C6.9375 14.1134 11.1055 18 16.65 18C17.628 18.0014 18.6021 17.8748 19.5471 17.6234C19.8774 17.5351 20.2287 17.5716 20.5336 17.7258C21.0706 17.9972 22.2541 18.5151 24.1883 18.9623C23.9185 18.0583 23.7336 17.1313 23.6361 16.1931Z"
          fill="white"
        />
        <path
          d="M23.6361 16.1931C23.6166 15.9904 23.6422 15.7859 23.7111 15.5942C23.7799 15.4025 23.8903 15.2283 24.0343 15.084C25.505 13.6108 26.3625 11.7249 26.3625 9.69231C26.3625 5.27262 22.1944 1.38462 16.65 1.38462C11.1055 1.38462 6.9375 5.27262 6.9375 9.69231C6.9375 14.1134 11.1055 18 16.65 18C17.628 18.0014 18.6021 17.8748 19.5471 17.6234C19.8774 17.5351 20.2287 17.5716 20.5336 17.7258C21.0706 17.9972 22.2541 18.5151 24.1883 18.9623C23.9185 18.0583 23.7336 17.1313 23.6361 16.1931Z"
          fill="#618DFF"
        />
        <path
          d="M20.8125 9.69235C20.8125 10.0596 20.9587 10.4118 21.2189 10.6714C21.4791 10.9311 21.832 11.077 22.2 11.077C22.568 11.077 22.9209 10.9311 23.1811 10.6714C23.4413 10.4118 23.5875 10.0596 23.5875 9.69235C23.5875 9.32513 23.4413 8.97295 23.1811 8.71328C22.9209 8.45362 22.568 8.30774 22.2 8.30774C21.832 8.30774 21.4791 8.45362 21.2189 8.71328C20.9587 8.97295 20.8125 9.32513 20.8125 9.69235ZM15.2625 9.69235C15.2625 10.0596 15.4087 10.4118 15.6689 10.6714C15.9291 10.9311 16.282 11.077 16.65 11.077C17.018 11.077 17.3709 10.9311 17.6311 10.6714C17.8913 10.4118 18.0375 10.0596 18.0375 9.69235C18.0375 9.32513 17.8913 8.97295 17.6311 8.71328C17.3709 8.45362 17.018 8.30774 16.65 8.30774C16.282 8.30774 15.9291 8.45362 15.6689 8.71328C15.4087 8.97295 15.2625 9.32513 15.2625 9.69235ZM11.1 11.077C10.732 11.077 10.3791 10.9311 10.1189 10.6714C9.85871 10.4118 9.71252 10.0596 9.71252 9.69235C9.71252 9.32513 9.85871 8.97295 10.1189 8.71328C10.3791 8.45362 10.732 8.30774 11.1 8.30774C11.468 8.30774 11.8209 8.45362 12.0811 8.71328C12.3413 8.97295 12.4875 9.32513 12.4875 9.69235C12.4875 10.0596 12.3413 10.4118 12.0811 10.6714C11.8209 10.9311 11.468 11.077 11.1 11.077Z"
          fill="white"
        />
        <path
          d="M3.00394 23.7272L3.03169 23.7216C5.57081 23.219 7.12204 22.5558 7.84354 22.1916C8.90579 22.4744 10.0006 22.6168 11.1 22.6153C17.23 22.6153 22.2 18.2759 22.2 12.923C22.2 7.5701 17.23 3.23071 11.1 3.23071C4.97002 3.23071 0 7.5701 0 12.923C0 15.3599 1.03091 17.5892 2.73337 19.2923C2.61427 20.3862 2.37034 21.4631 2.00632 22.5018L2.00216 22.517C1.89872 22.8145 1.78581 23.1087 1.66361 23.399C1.554 23.6566 1.76629 23.9446 2.0424 23.9003C2.36383 23.8478 2.68439 23.7901 3.00394 23.7272ZM4.11394 19.4238C4.13337 19.2211 4.10776 19.0166 4.03893 18.8249C3.9701 18.6332 3.85974 18.459 3.71573 18.3147C2.24498 16.8415 1.3875 14.9556 1.3875 12.923C1.3875 8.50333 5.55555 4.61533 11.1 4.61533C16.6445 4.61533 20.8125 8.50333 20.8125 12.923C20.8125 17.3441 16.6445 21.2307 11.1 21.2307C10.122 21.2321 9.14794 21.1055 8.2029 20.8541C7.87264 20.7658 7.52134 20.8023 7.21639 20.9566C6.67943 21.2279 5.49589 21.7458 3.56171 22.193C3.83155 21.2891 4.01642 20.362 4.11394 19.4238Z"
          fill="white"
        />
        <path
          d="M4.11394 19.4238C4.13337 19.2211 4.10776 19.0166 4.03893 18.8249C3.9701 18.6332 3.85974 18.459 3.71573 18.3147C2.24498 16.8415 1.3875 14.9556 1.3875 12.923C1.3875 8.50333 5.55555 4.61533 11.1 4.61533C16.6445 4.61533 20.8125 8.50333 20.8125 12.923C20.8125 17.3441 16.6445 21.2307 11.1 21.2307C10.122 21.2321 9.14794 21.1055 8.2029 20.8541C7.87264 20.7658 7.52134 20.8023 7.21639 20.9566C6.67943 21.2279 5.49589 21.7458 3.56171 22.193C3.83155 21.2891 4.01642 20.362 4.11394 19.4238Z"
          fill="#618DFF"
        />
        <path
          d="M6.775 13.3846C6.775 13.7518 6.62882 14.104 6.36861 14.3637C6.1084 14.6234 5.75549 14.7692 5.3875 14.7692C5.01951 14.7692 4.6666 14.6234 4.40639 14.3637C4.14618 14.104 4 13.7518 4 13.3846C4 13.0174 4.14618 12.6652 4.40639 12.4055C4.6666 12.1459 5.01951 12 5.3875 12C5.75549 12 6.1084 12.1459 6.36861 12.4055C6.62882 12.6652 6.775 13.0174 6.775 13.3846ZM12.325 13.3846C12.325 13.7518 12.1788 14.104 11.9186 14.3637C11.6584 14.6234 11.3055 14.7692 10.9375 14.7692C10.5695 14.7692 10.2166 14.6234 9.95639 14.3637C9.69618 14.104 9.55 13.7518 9.55 13.3846C9.55 13.0174 9.69618 12.6652 9.95639 12.4055C10.2166 12.1459 10.5695 12 10.9375 12C11.3055 12 11.6584 12.1459 11.9186 12.4055C12.1788 12.6652 12.325 13.0174 12.325 13.3846ZM16.4875 14.7692C16.8555 14.7692 17.2084 14.6234 17.4686 14.3637C17.7288 14.104 17.875 13.7518 17.875 13.3846C17.875 13.0174 17.7288 12.6652 17.4686 12.4055C17.2084 12.1459 16.8555 12 16.4875 12C16.1195 12 15.7666 12.1459 15.5064 12.4055C15.2462 12.6652 15.1 13.0174 15.1 13.3846C15.1 13.7518 15.2462 14.104 15.5064 14.3637C15.7666 14.6234 16.1195 14.7692 16.4875 14.7692Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_277_45323">
          <rect
            width="27.75"
            height="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
