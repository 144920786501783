import { FC } from 'react';

import { Wrapper, Text, Container } from './style';

interface CurrentDateProps {
  date: number;
}

export const CurrentDate: FC<CurrentDateProps> = (props) => {
  const { date } = props;

  const isThisYear = new Date(date).getFullYear() === new Date().getFullYear();

  return (
    <Container isVisible={true}>
      <Wrapper>
        <Text>
          {new Date(date).toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            ...(isThisYear ? {} : { year: 'numeric' }),
          })}
        </Text>
      </Wrapper>
    </Container>
  );
};
