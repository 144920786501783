import { Filter, Search } from './components';
import { Wrapper } from './styles';

export const SearchFilters = () => {
  return (
    <Wrapper>
      <Search />

      <Filter />
    </Wrapper>
  );
};
