import NiceModal from '@ebay/nice-modal-react';
import { FC } from 'react';
import { Provider } from 'react-redux';

import { PropsWithChildren } from '@/types/helpers';

import { ChatProvider, AuthProvider, ChatOpenedProvider } from '@/contexts';
import store from '@/store';

interface MainLayoutProps extends PropsWithChildren {}

export const MainLayout: FC<MainLayoutProps> = (props) => {
  const { children } = props;

  return (
    <Provider store={store}>
      <NiceModal.Provider>
        <AuthProvider>
          <ChatOpenedProvider>
            <ChatProvider>{children}</ChatProvider>
          </ChatOpenedProvider>
        </AuthProvider>
      </NiceModal.Provider>
    </Provider>
  );
};
