import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

interface InitialStateProps {
  [key: string]: number;
}

export interface SetRoomPayload {
  key: number;
  value: number;
}

export const initialState: InitialStateProps = {};

export const slice = createSlice({
  name: 'room-visited-slice',
  initialState,

  reducers: {
    setRoomScroll: (state, { payload }: PayloadAction<SetRoomPayload>) => {
      state[payload.key] = payload.value;
    },
    reset: (state) => {
      state = {};
    },
  },
});

export default slice.reducer;

export const { setRoomScroll, reset } = slice.actions;
