import styled from 'styled-components';

import { Hideble } from '@/components/styles/containers';

interface CompanyItemProps {
  isSelected: boolean;
}

export const CompanyItemWrapper = styled.div<CompanyItemProps>`
  position: relative;
  border-top: 0px;
  border-right: 0px;
  height: 32px;
  padding: 0px 6px 0px 6px;
  display: flex;
  display: flex;
  cursor: pointer;
  gap: 6px;
  transition: all 0.3s ease;

  color: ${(props) => (props.isSelected ? '#467aff' : '#272e39')};
  background-color: ${(props) => (props.isSelected ? '#ecf1ff' : '#fff')};

  border: 0.5px solid #e7e8e9;
  border-bottom: 0px;

  &:hover {
    background-color: #ecf1ff;
  }
`;

export const Indicator = styled(Hideble)`
  height: 100%;
  position: absolute;
  width: 1px;
  background-color: #467aff;
  top: 0;
  right: 0px;
  bottom: 0px;
`;
