export const SITES = {
  UAT: {
    ws: 'wss://chat.bidfinance-uat.pl:8090',
    http: 'https://chat.bidfinance-uat.pl',
    origin: 'https://www.bidfinance-uat.pl',
  },
  PROD: {
    ws: 'wss://chat.bidfinance.pl:8090',
    http: 'https://chat.bidfinance.pl',
    origin: 'https://www.bidfinance.pl',
  },
};

export const getEnv = () => {
  let { REACT_APP_WS_SERVER_URL, REACT_APP_HTTP_SERVER_URL } =
    process.env || {};

  switch (window.location.origin) {
    case SITES.UAT.origin: {
      const { http, ws } = SITES.UAT;

      REACT_APP_HTTP_SERVER_URL = http;
      REACT_APP_WS_SERVER_URL = ws;
      break;
    }

    case SITES.PROD.origin: {
      const { http, ws } = SITES.PROD;

      REACT_APP_HTTP_SERVER_URL = http;
      REACT_APP_WS_SERVER_URL = ws;
      break;
    }
  }

  return {
    HTTP_SERVER_URL: REACT_APP_HTTP_SERVER_URL,
    WS_SERVER_URL: REACT_APP_WS_SERVER_URL,
  };
};
