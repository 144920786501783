export const NoMessagesIcon = () => {
  return (
    <svg
      width="112"
      height="80"
      viewBox="0 0 112 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56 80C78.0914 80 96 62.0914 96 40C96 17.9086 78.0914 0 56 0C33.9086 0 16 17.9086 16 40C16 62.0914 33.9086 80 56 80Z"
        fill="#DEE7FF"
      />
      <g filter="url(#filter0_d_286_45999)">
        <rect
          x="6"
          y="29"
          width="67"
          height="18"
          rx="4"
          fill="white"
        />
        <rect
          width="22"
          height="4"
          rx="2"
          transform="matrix(-1 0 0 1 36 33)"
          fill="#B4DAFF"
        />
        <rect
          width="51"
          height="2"
          rx="1"
          transform="matrix(-1 0 0 1 65 41)"
          fill="#F1F4F6"
        />
      </g>
      <g clipPath="url(#clip0_286_45999)">
        <path
          d="M11.5 50.7L14 46.7H22L11.5 50.7Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_286_45999)">
        <rect
          x="39"
          y="38.7"
          width="67"
          height="17"
          rx="4"
          fill="#618DFF"
        />
        <rect
          width="22"
          height="3"
          rx="1.5"
          transform="matrix(-1 0 0 1 69 42.7)"
          fill="#B4DAFF"
        />
        <rect
          width="51"
          height="2"
          rx="1"
          transform="matrix(-1 0 0 1 98 49.7)"
          fill="#F1F4F6"
        />
      </g>
      <g clipPath="url(#clip1_286_45999)">
        <path
          d="M100.5 59.3999L98 55.3999H90L100.5 59.3999Z"
          fill="#618DFF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_286_45999"
          x="0"
          y="25"
          width="79"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_286_45999"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_286_45999"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_286_45999"
          x="33"
          y="34.7"
          width="79"
          height="29"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_286_45999"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_286_45999"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_286_45999">
          <rect
            width="16"
            height="5"
            fill="white"
            transform="translate(6 46.7)"
          />
        </clipPath>
        <clipPath id="clip1_286_45999">
          <rect
            width="16"
            height="5"
            fill="white"
            transform="matrix(-1 0 0 1 106 55.3999)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
