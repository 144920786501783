import { createContext } from 'react';

export interface ChatContextProps {
  client: WebSocket | null;
  sendMessage: (type: string, data?: Object) => void;
  resetClient: () => void;
  isClosed: boolean;
  setClosed: (val: boolean) => void;
  isConnected: boolean;
  lockHistory: () => void;
  unlockHistory: () => void;
}

export const ChatContext = createContext<ChatContextProps>({
  client: null,
  sendMessage: () => {},
  resetClient: () => {},
  isClosed: false,
  setClosed: () => {},
  isConnected: false,
  lockHistory: () => {},
  unlockHistory: () => {},
});
