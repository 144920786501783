import { useEffect, useState } from 'react';

export const useChatTriggers = () => {
  const [openedAuction, setOpenedAuction] = useState<string>();
  const [openedLot, setopenedLot] = useState<string>();

  useEffect(() => {
    const interval = setInterval(() => {
      const openedAuction = localStorage.getItem('opened-auction') || '';
      const openedLot = localStorage.getItem('opened-lot') || '';

      setOpenedAuction(openedAuction);
      setopenedLot(openedLot);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return { openedAuction, openedLot };
};
