import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export const messagesSelector = createSelector(
  (state: RootState) => state.messages,
  (messages) => messages.messages
);

export const triggerSelector = createSelector(
  (state: RootState) => state.messages,
  (messages) => messages.trigger
);

export const messagesReadSelector = createSelector(
  (state: RootState) => state.messages,
  (messages) => messages.messagesRead
);
