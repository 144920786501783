import { FC } from 'react';

import { CheckIcon } from '@/components/ui/icons';

import { Content, Wrapper } from './styles';

interface CheckboxProps {
  value: boolean;
  onChange: () => void;
  color?: string;
  label?: string;
  count?: number;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { onChange, value, color = '#0496FF', disabled = false } = props;

  return (
    <Wrapper
      disabled={disabled}
      onClick={disabled ? () => {} : onChange}
    >
      <Content
        color={color}
        isChecked={value}
      >
        <CheckIcon />
      </Content>
    </Wrapper>
  );
};
