import { RefObject, useEffect } from 'react';

import { CHAT_SCROLL_HEIGHT, MESSAGES_PAGINATION } from '@/constants/messages';
import { MESSAGE_TYPES } from '@/constants/websockets';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';
import { triggerSelector } from '@/store/selectors/messages-selector';
import { paginationScrollSelector } from '@/store/selectors/pagination-scroll.selector';
import { changeElementHeight } from '@/store/slices/pagination-scroll.slice';
import { setRoomScroll } from '@/store/slices/room-visited.slice';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { useChatContext } from '@/contexts';

export const useLogic = (containerRef: RefObject<HTMLDivElement>) => {
  const currentRoom = useAppSelector(currentRoomSelector);
  const messages = useAppSelector((state) => state.messages.messages);
  const trigger = useAppSelector(triggerSelector);
  const dispatch = useAppDispatch();

  const roomId = currentRoom?.id as number;

  const scrollBeforepagination = useAppSelector(
    paginationScrollSelector(roomId)
  );
  const { client, sendMessage } = useChatContext();

  const beforeAutoload = () => {
    dispatch(
      changeElementHeight({
        key: roomId,
        value: containerRef.current?.scrollHeight as number,
      })
    );
  };

  useEffect(() => {
    if (trigger) {
      containerRef.current?.scrollTo({
        top: CHAT_SCROLL_HEIGHT,
      });
    }
  }, [messages]);

  useEffect(() => {
    containerRef.current?.scrollTo({
      top: CHAT_SCROLL_HEIGHT,
    });
  }, [currentRoom]);

  useEffect(() => {
    setRoomScroll({
      key: roomId,
      value: containerRef.current?.scrollTop as number,
    });
  }, [messages]);

  useEffect(() => {
    if (messages[roomId]?.length <= MESSAGES_PAGINATION) {
      containerRef.current?.scrollTo({
        top: CHAT_SCROLL_HEIGHT,
      });
    } else if (!trigger) {
      containerRef.current?.scrollTo({
        top: containerRef.current.scrollHeight - scrollBeforepagination,
      });
    }
  }, [messages]);

  useEffect(() => {
    let scrollTop: number = 0;

    const interval = setInterval(() => {
      scrollTop = containerRef.current?.scrollTop as number;
    }, 800);

    return () => {
      clearInterval(interval);

      dispatch(
        setRoomScroll({
          key: roomId,
          value: scrollTop as number,
        })
      );
    };
  }, [currentRoom]);

  useEffect(() => {
    if (client && currentRoom && !messages[currentRoom.id]?.length) {
      sendMessage(MESSAGE_TYPES.PAGINATION, {
        roomId: roomId,
        latestId: 0,
        limit: MESSAGES_PAGINATION,
      });
    }
  }, [currentRoom]);

  return { beforeAutoload };
};
