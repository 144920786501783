import { Fragment, useRef } from 'react';

import { IMessage } from '@/types/chat';
import { IObject } from '@/types/helpers';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';
import { messagesSelector } from '@/store/selectors/messages-selector';

import { useAppSelector } from '@/hooks/redux';

import {
  Message,
  SendMessage,
  ChatHeader,
  NoChatsSelected,
  NoMessages,
  ScrollDown,
} from './components';
import { Autoloader, CurrentDate } from './components';
import { useLogic } from './hooks';
import {
  ChatboxContent,
  MessagesContainer,
  Wrapper,
  ScrollDownWrapper,
} from './styles';
import { useScrollHeight } from '@/hooks';

export const ChatBox = () => {
  const currentRoom = useAppSelector(currentRoomSelector);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isBiggerThanHeight, scrollDown } = useScrollHeight(containerRef);
  const messages = useAppSelector(messagesSelector);

  const { beforeAutoload } = useLogic(containerRef);

  const uniqueMessages = () => {
    const dict: IObject<number> = {};

    return messages[currentRoom?.id as number].filter((item) => {
      if (dict[item.id]) {
        return false;
      }

      dict[item.id] = 1;

      return true;
    });
  };

  const getChatContent = () => {
    if (!currentRoom) {
      return <NoChatsSelected />;
    }

    return (
      <ChatboxContent ref={containerRef}>
        {messages[currentRoom.id] ? (
          <MessagesContainer>
            <Autoloader beforeAutoload={beforeAutoload} />
            {uniqueMessages().map((message: IMessage, index: number) => {
              const prevMessage = messages[currentRoom.id][index - 1];

              const currentDate = new Date(message.createdAt).getDate();
              const dateBefore = new Date(
                prevMessage?.createdAt || message.createdAt
              ).getDate();

              return (
                <Fragment key={message.id}>
                  {currentDate > dateBefore && (
                    <CurrentDate date={message.createdAt} />
                  )}

                  <Message message={message} />
                </Fragment>
              );
            })}
          </MessagesContainer>
        ) : (
          <NoMessages />
        )}

        <ScrollDownWrapper>
          <ScrollDown
            isVisible={isBiggerThanHeight}
            onClick={scrollDown}
          />
        </ScrollDownWrapper>
      </ChatboxContent>
    );
  };

  return (
    <Wrapper>
      {currentRoom && <ChatHeader />}

      {getChatContent()}

      {currentRoom && <SendMessage />}
    </Wrapper>
  );
};
