import styled from 'styled-components';

export interface HidebleProps {
  isVisible: boolean;
}

export const Hideble = styled.div<HidebleProps>`
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;
