import { memo } from 'react';
import { FC } from 'react';
import { useEffect } from 'react';

import { IMessage } from '@/types/chat';

import { useAdmiAccess } from '@/pages/home/hooks';

import { GET_HISTORY_STATUSES } from '@/constants/history';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';
import { messagesReadSelector } from '@/store/selectors/messages-selector';
import {
  updateLastReadMessage,
  updateNotificationHistory,
} from '@/store/slices/messages.slice';

import { WhiteArrow, BlueArrow } from '@/components/ui/icons/message-arrows';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { BuyerName, MessageWrapper, Text, Wrapper, Tail } from './style';
import { useAuthContext } from '@/contexts';
import { useChatContext } from '@/contexts';
import { HistoryService } from '@/services';

interface MessageProps {
  message: IMessage;
}

export const Message: FC<MessageProps> = memo((props) => {
  const { message } = props;
  const { user, companyUuid } = useAuthContext();
  const dispatch = useAppDispatch();
  const readMessages = useAppSelector(messagesReadSelector);
  const currentRoom = useAppSelector(currentRoomSelector);
  const { lockHistory, unlockHistory } = useChatContext();

  const { computeAccess } = useAdmiAccess();

  const isOwner = message.userUuid === user?.sub;

  const updateHistory = async () => {
    lockHistory();
    const { hasUser, isAdmin } = computeAccess();

    if (isAdmin || !hasUser) {
      return;
    }

    const history = await HistoryService.updateCurrentHistory();
    dispatch(
      updateNotificationHistory({
        data: history,
        status: GET_HISTORY_STATUSES.GET,
      })
    );
    unlockHistory();
  };

  useEffect(() => {
    const isSeller = localStorage.getItem('show-mine-' + user?.sub) === 'true';

    const lastMessageData =
      readMessages?.[companyUuid]?.[currentRoom!.auctionUuid]?.[
        currentRoom!.lotUuid
      ];

    const lastMessage = isSeller
      ? lastMessageData[currentRoom!.buyerCompanyUuid][0]
      : lastMessageData[0];

    if (lastMessage <= message.id && lastMessage) {
      dispatch(
        updateLastReadMessage({
          auctionUuid: currentRoom!.auctionUuid,
          lotUuid: currentRoom!.lotUuid,
          value: message.id,
          isSeller: isSeller as boolean,
          buyerCompanyUuid: currentRoom?.buyerCompanyUuid as string,
        })
      );

      setTimeout(() => {
        updateHistory();
      }, 200);
    }
  }, []);

  return (
    <Wrapper isOwner={isOwner}>
      <BuyerName isOwner={isOwner}>
        {new Date(message.createdAt).toLocaleString().split(',')[1]}
      </BuyerName>

      <MessageWrapper isOwner={isOwner}>
        <Text isOwner={isOwner}>{message.message}</Text>
      </MessageWrapper>

      <Tail isOwner={isOwner}>{isOwner ? <BlueArrow /> : <WhiteArrow />}</Tail>
    </Wrapper>
  );
});
