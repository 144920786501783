import { useMemo } from 'react';

import { ISellerAuction } from '@/types/auction';
import { IRoom } from '@/types/chat';
import { IObject } from '@/types/helpers';

import { useAuthContext } from '@/contexts';

export const useConvertRoomsToAuctions = (
  rooms: IRoom[]
): { auctions: ISellerAuction[] } => {
  const dict: IObject<number> = {};
  const lots: IObject<IObject<number>> = {};
  const { companyUuid } = useAuthContext();

  const auctions = useMemo(
    () =>
      rooms
        .filter(
          (room) =>
            room.sellerCompanyUuid === companyUuid ||
            room.buyerCompanyUuid === companyUuid
        )
        .filter((room) => {
          if (dict[room?.auctionUuid]) {
            return false;
          }
          dict[room?.auctionUuid] = 1;
          return true;
        })
        .map((item) => ({
          auctionUuid: item?.auctionUuid,
          auctionNumber: item.auctionNumber,
          auctionStatus: item.status,
          sellerCompanyUuid: item.sellerCompanyUuid,
          sellerCompanyName: item.sellerCompanyName,
          lastMessageId: item.lastMessageId,
          firstMessageId: item.firstMessageId,
          buyerCompanyUuid: item.buyerCompanyUuid,

          lots: rooms
            .filter((room) => {
              if (!lots[room?.auctionUuid]) {
                lots[room?.auctionUuid] = {};
              }

              if (lots[room?.auctionUuid][room.lotNumber]) {
                return false;
              }

              lots[room?.auctionUuid][room.lotNumber] =
                room?.auctionUuid !== item?.auctionUuid ? 0 : 1;

              return room?.auctionUuid === item?.auctionUuid;
            })
            .map((room) => {
              return {
                auctionUuid: room?.auctionUuid,
                lotUuid: room.lotUuid,
                lotNumber: room.lotNumber,
                sellerCompanyUuid: room.sellerCompanyUuid,
                sellerCompanyName: room.sellerCompanyName,
                status: room.status,
                lastMessageId: item.lastMessageId,
                firstMessageId: item.firstMessageId,
              };
            })
            .sort((a, b) => +a.lotNumber - +b.lotNumber),
        })),
    [rooms]
  );

  return { auctions };
};
