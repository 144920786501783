export const CheckIcon = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76896 0.481295C7.95202 0.664358 7.95201 0.961154 7.76895 1.14421L3.39395 5.51901C3.2109 5.70206 2.91412 5.70206 2.73106 5.51902L0.543558 3.33172C0.360492 3.14867 0.360479 2.85187 0.543529 2.6688C0.726579 2.48574 1.02338 2.48572 1.20644 2.66877L3.06249 4.52466L7.10605 0.48128C7.28911 0.298226 7.58591 0.298233 7.76896 0.481295Z"
        fill="white"
      />
    </svg>
  );
};
