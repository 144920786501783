import { useTranslation } from 'react-i18next';

import { NoMessagesIcon } from '@/components/ui/icons';

import { Text, Wrapper } from './styles';

export const NoMessages = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <NoMessagesIcon />

      <Text>{t('placeholders.no_messages')}</Text>
    </Wrapper>
  );
};
