import { useEffect, useState } from 'react';

import { useAuthContext } from '@/contexts';

export const useSellerOrBuyer = () => {
  const [isSeller, setIsSeller] = useState<boolean>();

  const { user } = useAuthContext();

  useEffect(() => {
    if (user?.sub) {
      const interval = setInterval(() => {
        const isSeller =
          localStorage.getItem('show-mine-' + user?.sub) === 'true';

        setIsSeller(isSeller);
      }, 300);

      return () => clearInterval(interval);
    }
  }, [user]);

  return { isSeller };
};
