import { forwardRef, ReactNode, RefObject, useEffect, useState } from 'react';

import { PropsWithChildren } from '@/types/helpers';

import { AUCTION_COLORS, AUCTION_COLORS_LIGHT } from '@/constants/auction';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';

import { ArrowDownIcon } from '@/components/ui/icons';

import { useAppSelector } from '@/hooks/redux';

import {
  Wrapper,
  ArrowWrapper,
  ArrowContainer,
  TextWrapper,
  Indicator,
} from './style';

interface SpoilerProps extends PropsWithChildren {
  initialValue?: boolean;
  color?: string;
  textColor?: string;
  title?: ReactNode;
  paddingR?: string;
  auctionStatus: string;
  isLot?: boolean;
  borderTop?: boolean;
  onClose?: () => void;
  trigger?: boolean;
  hiddenContent?: ReactNode;
}

export const Spoiler = forwardRef<HTMLDivElement, SpoilerProps>(
  (props, ref) => {
    const {
      children,
      initialValue = false,
      textColor = '#E1E2ED',
      title,
      paddingR,
      auctionStatus,
      isLot = false,
      borderTop = false,
      onClose = () => {},
      trigger = false,
      hiddenContent,
    } = props;

    const [isOpen, setOpen] = useState(initialValue);
    const currentRoom = useAppSelector(currentRoomSelector);
    const toggleOpen = () => setOpen((prev) => !prev);

    useEffect(() => {
      if (!isOpen) {
        onClose();
      }
    }, [isOpen]);

    useEffect(() => {
      setOpen(trigger);
    }, [trigger]);

    return (
      <div ref={ref as RefObject<HTMLDivElement>}>
        <Wrapper
          borderTop={borderTop}
          isOpen={isOpen}
          isRoomSelected={!!currentRoom}
          isLot={isLot}
          backgroundColor={
            isOpen ? AUCTION_COLORS_LIGHT[auctionStatus] : undefined
          }
          onClick={toggleOpen}
          textColor={textColor}
          style={{
            paddingRight: paddingR,
          }}
        >
          <ArrowContainer
            isLot={isLot}
            backgroundColor={
              isOpen ? 'transparent' : AUCTION_COLORS_LIGHT[auctionStatus]
            }
          >
            <ArrowWrapper isOpen={isOpen}>
              <ArrowDownIcon
                fillColor={isOpen ? AUCTION_COLORS[auctionStatus] : undefined}
              />
            </ArrowWrapper>
          </ArrowContainer>

          <TextWrapper
            color={
              isOpen
                ? isLot
                  ? currentRoom
                    ? '#272E39'
                    : '#467AFF'
                  : AUCTION_COLORS[auctionStatus]
                : '#272E39'
            }
          >
            <>{title}</>

            <>{!isOpen && hiddenContent}</>
          </TextWrapper>

          {isLot && !currentRoom && <Indicator isVisible={isOpen} />}
        </Wrapper>

        {isOpen && <div>{children}</div>}
      </div>
    );
  }
);
