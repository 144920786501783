import { useState } from 'react';

export const useDisclosure = (initialState = false) => {
  const [isOpen, setOpen] = useState(initialState);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onToggle = () => {
    setOpen((prev) => !prev);
  };

  return { isOpen, onClose, onOpen, onToggle };
};
