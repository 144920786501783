import styled from 'styled-components';

import { Hideble } from '@/components/styles/containers';

export const ButtonWrapper = styled(Hideble)`
  position: absolute;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  backdrop-filter: blur(8px);

  background: rgba(255, 255, 255, 0.3);
  border-radius: 999px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;
