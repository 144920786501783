import { RefObject, useEffect, useState } from 'react';

export const useScrollHeight = (ref: RefObject<HTMLDivElement>) => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setScroll(ref.current?.scrollTop as number);
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [ref]);

  const scrollDown = () => {
    ref.current?.scrollBy({
      behavior: 'smooth',
      top: 1000000,
    });
  };

  const isBiggerThanHeight =
    (ref.current?.scrollHeight || 0) >= (ref.current?.clientHeight || 1) * 2 &&
    scroll <
      (ref.current?.scrollHeight || 1) - (ref.current?.clientHeight || 1) * 3;

  return {
    scroll,
    isBiggerThanHeight: isBiggerThanHeight,
    scrollDown,
  };
};
