import { useTranslation } from 'react-i18next';

import { NoChatsSelectedIcon } from '@/components/ui/icons';

import { Text, Wrapper } from './styles';

export const NoChatsSelected = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <NoChatsSelectedIcon />

      <Text>{t('placeholders.no_chat_selected')}</Text>
    </Wrapper>
  );
};
