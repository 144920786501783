import { FC, useContext } from 'react';

import { PropsWithChildren } from '@/types/helpers';

import { AuthContext, AuthContextProps } from './context';
import { useLogic } from './hooks';

export const AuthProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const { token, user, companyUuid } = useLogic();

  return (
    <AuthContext.Provider
      value={{ user: user, token: token || '', companyUuid }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext<AuthContextProps>(AuthContext);
