import styled from 'styled-components';

export const DefaultScrollbarContainer = styled.div`
  ::-webkit-scrollbar {
    backface-visibility: hidden;
    height: 0px;
    background: transparent;
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 4px;
    cursor: pointer;

    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
`;
