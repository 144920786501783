import { FC, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ILot, ISellerAuction } from '@/types/auction';
import { IObject } from '@/types/helpers';

import { LOT_NUMBERS } from '@/constants/auction';

import { removeCurrentRoom } from '@/store/slices/messages.slice';

import { MessageBadge } from '@/components/ui/badges';
import { Spoiler } from '@/components/ui/containers';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { LotTitle } from '../../styles';
import { CompanyItem } from '../company';

import { useGetRoomNotified } from './utils';
import { useChatOpenedContext } from '@/contexts';
import { useAuthContext } from '@/contexts';

interface LotProps {
  lot: ILot;
  auction: ISellerAuction;
}

export const Lot: FC<LotProps> = (props) => {
  const { lot, auction } = props;
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState<boolean>(false);
  const { companyUuid } = useAuthContext();

  const { openedLot } = useChatOpenedContext();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (lot.lotUuid === openedLot) {
      setTrigger(true);
    } else if (openedLot && openedLot !== lot.lotUuid) {
      setTrigger(false);
    }
  }, [openedLot]);

  const rooms = useAppSelector((state) => {
    const dict: IObject<number> = {};

    return state.messages.rooms
      .filter(
        (room) =>
          room.lotUuid === lot.lotUuid && room.sellerCompanyUuid === companyUuid
      )
      .filter((room) => {
        if (dict[room.buyerCompanyName]) {
          return false;
        }

        dict[room.buyerCompanyName] = 1;
        return true;
      });
  });

  const { hasNotifications } = useGetRoomNotified(rooms);

  return (
    <Spoiler
      auctionStatus={auction.auctionStatus}
      paddingR="14px"
      trigger={trigger}
      onClose={() => dispatch(removeCurrentRoom())}
      isLot
      title={
        <LotTitle hasNotifications={hasNotifications}>
          {lot.lotNumber === LOT_NUMBERS.ALL_CASE
            ? t('common.all_case')
            : t('common.case_part')}{' '}
          #{auction.auctionNumber}.{lot.lotNumber}
          {hasNotifications && <MessageBadge />}
        </LotTitle>
      }
    >
      <div>
        {[...rooms]
          .sort((a, b) => a.buyerCompanyName.length - b.buyerCompanyName.length)
          .map((room) => (
            <CompanyItem
              setNotificaiton={(value: boolean) => {
                // changeDict({ key: room.id.toString(), value: value });
              }}
              key={room.id}
              auction={auction}
              lot={lot}
              room={room}
            />
          ))}
      </div>
    </Spoiler>
  );
};
