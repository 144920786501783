import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { removeCurrentRoom } from '@/store/slices/messages.slice';

import { ChatIcon, CloseIcon } from '@/components/ui/icons';

import { useAppDispatch } from '@/hooks/redux';

import { Title, Wrapper, RightWrapper, Icon } from './styles';

// import { HistoryService } from '@/services';

interface HeaderProps {
  onClose: () => void;
}

export const Header: FC<HeaderProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCloseChat = () => {
    localStorage.removeItem('opened-auction');
    localStorage.removeItem('opened-lot');
    onClose();
    dispatch(removeCurrentRoom());
    // HistoryService.updateCurrentHistory();
  };

  return (
    <Wrapper>
      <RightWrapper>
        <ChatIcon />

        <Title>{t('header.description')}</Title>
      </RightWrapper>

      <Icon onClick={onCloseChat}>
        <CloseIcon />
      </Icon>
    </Wrapper>
  );
};
