import styled from 'styled-components';

import { TransparentInput } from '@/components/styles/forms';

interface Errorable {
  isError: boolean;
}

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border: 0.8px solid #e7e8e9;
  padding: 4px 8px;
  border-left: 0px;
  border-bottom: 0px;
  height: 35px;
`;

export const Input = styled(TransparentInput)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1d1d1d;
  ::placeholder {
    color: #c2c2c2;
  }
  &:disabled {
    opacity: 1;
    background-color: transparent;
    cursor: not-allowed;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 6px;
`;

export const IconButtonWrapper = styled.div`
  color: #979797;
  font-size: 22px;
`;

export const Form = styled.form`
  width: 100%;
  position: relative;
`;

export const MaxSymbolText = styled.div<Errorable>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isError ? '#dc3545' : '#c2c2c2')};
  position: absolute;
  bottom: -7px;
  right: 5px;
  transition: all 0.3s ease;
`;
