import styled from 'styled-components';

import { TransparentInput } from '@/components/styles/forms';

export const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 2px;

  background: #ffffff;
  border: 0.5px solid #d1d1d1;
  border-radius: 5px;
`;

export const Input = styled(TransparentInput)`
  color: #c2c2c2;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: #1d1d1d;

  ::placeholder {
    color: #c2c2c2;
  }
`;
