import { useRef } from 'react';

// import { useTranslation } from 'react-i18next';
import { AUCTION_STATUSES } from '@/constants/auction';

import { FilterIcon } from '@/components/ui/icons';

import { CheckboxItem } from './components';
import { Wrapper, ListWrapper, CheckboxesWrapper, IconWrapper } from './styles';
import { useDisclosure, useOnClickOutside } from '@/hooks';

export const Filter = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, onClose);

  return (
    <ListWrapper ref={containerRef}>
      <IconWrapper onClick={onToggle}>
        <FilterIcon isActive={isOpen} />
      </IconWrapper>

      {isOpen && (
        <Wrapper isVisible={isOpen}>
          <CheckboxesWrapper>
            <CheckboxItem
              title="filters.current_auctions"
              value={AUCTION_STATUSES.InProgress}
            />

            <CheckboxItem
              title="filters.future_auctions"
              value={AUCTION_STATUSES.Scheduled}
            />

            <CheckboxItem
              title="filters.transactions"
              value={AUCTION_STATUSES.Finished}
            />

            <CheckboxItem
              title="filters.completed_auctions"
              value={AUCTION_STATUSES.Completed}
            />
          </CheckboxesWrapper>
        </Wrapper>
      )}
    </ListWrapper>
  );
};
