import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 6px 16px;
  gap: 4px;
  width: 100%;
  z-index: 2;

  position: relative;
  background: #ffffff;
  border-bottom: 0.5px solid #e7e8e9;
  box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.12);
  height: 43px;
`;

export const AuctionName = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #272e39;
`;

export const CompanyName = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #272e39;
`;

export const LotName = styled(CompanyName)`
  color: #20bf82;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RoomName = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
