import styled from 'styled-components';

import { Hideble } from '@/components/styles/containers';

interface Openable {
  isOpen: boolean;
}

export const ContentWrapper = styled.div`
  display: flex;
`;

export const Wrapper = styled(Hideble)`
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
`;

export const Container = styled.div<Openable>`
  position: fixed;
  transition: all 0.4s ease;
  width: ${(props) => (props.isOpen ? '480px' : '80px')};
  height: ${(props) => (props.isOpen ? '495px' : '80px')};
  right: 20px;
  bottom: 20px;
  z-index: 1000;
`;
