import { useRef, useEffect } from 'react';
import { useMemo, FC } from 'react';

import { IMessage } from '@/types/chat';

import { MESSAGE_TYPES } from '@/constants/websockets';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';

import { useAppSelector } from '@/hooks/redux';

import { AutoloaderComponent } from './style';
import { useChatContext } from '@/contexts';
import { useOnScreen } from '@/hooks';

interface AutoloaderProps {
  beforeAutoload: () => void;
}

export const Autoloader: FC<AutoloaderProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { sendMessage } = useChatContext();
  const { beforeAutoload } = props;

  const currentRoom = useAppSelector(currentRoomSelector);
  const currentMessages = useAppSelector(
    (state) => state.messages.messages[currentRoom?.id as number]
  );

  const latestId = useMemo(
    () => Math.min(...currentMessages.map((item: IMessage) => item.id)),
    [currentMessages]
  );

  const isVisible = useOnScreen(ref, '0px');

  useEffect(() => {
    if (isVisible) {
      beforeAutoload();
      sendMessage(MESSAGE_TYPES.PAGINATION, {
        roomId: currentRoom?.id,
        latestId: latestId,
        limit: 25,
      });
    }
  }, [isVisible]);

  return <AutoloaderComponent ref={ref} />;
};
