import styled from 'styled-components';

interface TextProps {
  twoDigits: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 30px;
  z-index: 400;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #f42b3d;
  position: absolute;
  right: -8px;
  top: -8px;
`;

export const Text = styled.div<TextProps>`
  color: #fff;
  font-weight: 700;
  font-family: 'Lato';
  font-size: ${(props) => (props.twoDigits ? '11px' : '13px')};
`;
