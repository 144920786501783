import styled from 'styled-components';

export const Hoverable = styled.div`
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #ecf1ff;
    color: #467aff;
  }
`;
