import { useEffect } from 'react';

import { useEngineReload } from '@/hooks/useEngineReload';

import { Header, Sidebar, ChatBox, OpenChatButton } from './components';
import { useAdmiAccess } from './hooks';
import { ContentWrapper, Wrapper, Container } from './styles';
import { useDisclosure } from '@/hooks';

export const Home = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isAdmin, hasUser, isLandingPage } = useAdmiAccess();

  useEngineReload();

  useEffect(() => {
    if (!hasUser) {
      onClose();
    }
  }, [hasUser]);

  return (
    <Container isOpen={isOpen}>
      <div>
        {hasUser && (
          <Wrapper isVisible={isOpen}>
            <Header onClose={onClose} />

            <ContentWrapper>
              <Sidebar />

              <ChatBox />
            </ContentWrapper>
          </Wrapper>
        )}

        {!isAdmin && hasUser && !isLandingPage && (
          <OpenChatButton
            isOpen={isOpen}
            onOpen={onOpen}
          />
        )}
      </div>
    </Container>
  );
};
