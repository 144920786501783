import { PlaceholderText, Wrapper } from './style';
import { useTranslation } from 'react-i18next';

export const NotFoundAuctions = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PlaceholderText>{t('sidebar.auctions_not_found')}</PlaceholderText>
    </Wrapper>
  );
};
