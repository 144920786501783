import styled from 'styled-components';

interface ICheckBox {
  isChecked: boolean;
  color: string;
}

interface DisablableProps {
  disabled: boolean;
}

export const Wrapper = styled.div<DisablableProps>`
  width: 12px;
  height: 12px;
  background: #ffffff;
  border: 1px solid #4f71ca;
  border-radius: 4px;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const Content = styled.div<ICheckBox>`
  background: #467aff;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isChecked ? '1' : '0')};
  visibility: ${(props) => (props.isChecked ? 'visible' : 'hidden')};
`;
