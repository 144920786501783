import { useEffect } from 'react';

import { useChatContext } from '@/contexts';

export const useEngineReload = () => {
  const { resetClient, isClosed, setClosed } = useChatContext();

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible' && isClosed) {
      resetClient();
      setClosed(false);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    window.addEventListener('focus', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      window.removeEventListener('focus', onVisibilityChange);
    };
  }, []);
};
