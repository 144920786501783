import { INotificationHistory } from '@/types/chat';

import { axiosInstance } from '@/lib';

export class HistoryService {
  public static async fetchCurrentHistory(): Promise<{
    data: INotificationHistory;
    status: string;
  }> {
    const companyUuid = localStorage.getItem('user-company-uuid');

    const { data } = await axiosInstance({
      url: `/history`,
      data: { companyUuid },
      method: 'POST',
    });

    return { data: data.data, status: data.status };
  }

  public static async updateCurrentHistory(): Promise<INotificationHistory> {
    const companyUuid = localStorage.getItem('user-company-uuid') || '';

    const readMessages = JSON.stringify(
      JSON.parse(localStorage.getItem('messages-read') || '{}')[companyUuid] ||
        '{}'
    );

    const { data } = await axiosInstance({
      url: `/history`,
      method: 'PUT',
      data: {
        companyUuid,
        content: readMessages,
        lastTimeUpdated: Number(localStorage.getItem('last-time-updated') || 0),
      },
    });

    return data.data;
  }
}
