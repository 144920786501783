import styled from 'styled-components';

import { Hideble } from '@/components/styles/containers';

interface WithBackground {
  backgroundColor?: string;
  isLot: boolean;
  isOpen?: boolean;
}

interface WrapperProps extends WithBackground {
  textColor: string;
  borderTop: boolean;
  isRoomSelected: boolean;
}

interface ArrowWrapperProps {
  isOpen: boolean;
}

interface TextWrapperProps {
  color: string;
}

export const Wrapper = styled.div<WrapperProps>`
  height: 32px;
  width: 100%;
  position: relative;
  color: ${(props) => props.textColor};
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;
  align-items: center;
  gap: 4px;

  background: ${(props) =>
    props.isLot
      ? props.isOpen && !props.isRoomSelected
        ? '#E9EFFF'
        : '#fff'
      : props.backgroundColor || '#fff'};
  border: 0.5px solid #e7e8e9;
  border-radius: 4px 4px 0px 0px;

  border-top: ${(props) => (props.borderTop ? 0.5 : 0)};
`;

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  transition: all 0.3s ease;
  transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowContainer = styled.div<WithBackground>`
  height: 100%;
  padding: 8px;
  background: ${(props) =>
    props.isLot ? 'transparent' : props.backgroundColor};
  border-radius: 0px 6px 6px 0px;
  width: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s;
`;

export const TextWrapper = styled.div<TextWrapperProps>`
  width: 100%;
  white-space: nowrap;
  max-width: 135px;

  color: ${(props) => props.color + ' !important'};
`;

export const Indicator = styled(Hideble)`
  height: 100%;
  position: absolute;
  width: 1px;
  background-color: #467aff;
  top: 0;
  right: 0px;
  bottom: 0px;
`;
