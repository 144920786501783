import { FC } from 'react';

interface FilterIconProps {
  isActive: boolean;
}

export const FilterIcon: FC<FilterIconProps> = (props) => {
  const { isActive } = props;

  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16667 10.25H8.83333C9.29167 10.25 9.66667 9.875 9.66667 9.41667C9.66667 8.95833 9.29167 8.58333 8.83333 8.58333H7.16667C6.70833 8.58333 6.33333 8.95833 6.33333 9.41667C6.33333 9.875 6.70833 10.25 7.16667 10.25ZM0.5 1.08333C0.5 1.54167 0.875 1.91667 1.33333 1.91667H14.6667C15.125 1.91667 15.5 1.54167 15.5 1.08333C15.5 0.625 15.125 0.25 14.6667 0.25H1.33333C0.875 0.25 0.5 0.625 0.5 1.08333ZM3.83333 6.08333H12.1667C12.625 6.08333 13 5.70833 13 5.25C13 4.79167 12.625 4.41667 12.1667 4.41667H3.83333C3.375 4.41667 3 4.79167 3 5.25C3 5.70833 3.375 6.08333 3.83333 6.08333Z"
        fill={isActive ? '#467AFF' : '#15171F'}
      />
    </svg>
  );
};
