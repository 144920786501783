import styled from 'styled-components';

import { Size } from '@/types/ui';

interface WrapperProps {
  size: Size;
}

export const Wrapper = styled.button<WrapperProps>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.size === 'sm' ? '8px' : '10px')};
  line-height: 125%;

  cursor: pointer;
  text-align: center;
  color: #0496ff;
  border-radius: 4px;
  padding: ${(props) => (props.size === 'sm' ? '4px 8px' : '6px 12px')};
  border-radius: ${(props) => (props.size === 'sm' ? '3px' : '6px')};
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    background-color: #0496ff26;
  }

  &:active {
    transform: scale(0.9);
  }
`;
