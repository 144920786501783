import { useState, useEffect } from 'react';

import { IUser } from '@/types/auth';

import { reset as resetFilters } from '@/store/slices/filters.slice';
import { reset as resetMessages } from '@/store/slices/messages.slice';
import { reset as resetPagination } from '@/store/slices/pagination-scroll.slice';
import { reset as resetRoomsVisited } from '@/store/slices/room-visited.slice';

import { useAppDispatch } from '@/hooks/redux';

export const useLogic = () => {
  const [user, setUser] = useState<null | IUser>(null);
  const [isUserLoaded, setUserLoaded] = useState<boolean>(false);
  const [companyUuid, setCompanyUuid] = useState<string>('');
  const [token, setToken] = useState<string>(
    localStorage.getItem('bidfinance-jwt') || ''
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentToken = localStorage.getItem('bidfinance-jwt') || '';
      const companyUuid = localStorage.getItem('user-company-uuid') || '';

      if (currentToken !== token) {
        setToken(currentToken);
      }
      setCompanyUuid(companyUuid);
    }, 800);

    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    if (!token) {
      setUser(null);
    }

    localStorage.removeItem('opened-auction');
    localStorage.removeItem('opened-lot');
  }, [token]);

  useEffect(() => {
    let tokenResponse = {} as IUser;
    const storageToken = localStorage.getItem('bidfinance-jwt');

    const nullableValues = ['undefined', 'null'];

    if (storageToken?.trim() && !nullableValues.includes(storageToken)) {
      tokenResponse = JSON.parse(atob(storageToken.split('.')[1]));

      if (user?.sub !== tokenResponse?.sub && tokenResponse.sub) {
        setUser(tokenResponse);
      }

      const companyUuid = localStorage.getItem('user-company-uuid') || '';
      setCompanyUuid(companyUuid);
    } else {
      setUser(null);
    }
  }, [token]);

  const resetStore = () => {
    dispatch(resetMessages());
    dispatch(resetFilters());
    dispatch(resetPagination());
    dispatch(resetRoomsVisited());
  };

  useEffect(() => {
    if (user && isUserLoaded) {
      resetStore();
    } else if (user) {
      setUserLoaded(true);
    }
  }, [user]);

  return { token, user, setUser, companyUuid };
};
