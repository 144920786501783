import { FC } from 'react';
import { useEffect } from 'react';

import { ISellerAuction, ILot } from '@/types/auction';
import { IRoom } from '@/types/chat';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';
import { messagesReadSelector } from '@/store/selectors/messages-selector';
import { setCurrentRoom } from '@/store/slices/messages.slice';

import { MessageBadge } from '@/components/ui/badges';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { CompanyTitle } from '../../styles';

import { CompanyItemWrapper, Indicator } from './style';
import { useAuthContext } from '@/contexts';

interface CompanyItemProps {
  auction: ISellerAuction;
  room: IRoom;
  lot: ILot;
  setNotificaiton: (val: boolean) => void;
}

export const CompanyItem: FC<CompanyItemProps> = (props) => {
  const { room, setNotificaiton } = props;
  const dispatch = useAppDispatch();
  const currentChat = useAppSelector(currentRoomSelector);
  const readMessages = useAppSelector(messagesReadSelector);
  const isSelected = currentChat?.id === room.id;
  const { companyUuid } = useAuthContext();

  const hasNotify =
    !!readMessages?.[companyUuid]?.[room!.auctionUuid]?.[room.lotUuid]?.[
      room.buyerCompanyUuid
    ]?.[2];

  const onSelectChat = () => {
    dispatch(setCurrentRoom(room));
  };

  useEffect(() => {
    setNotificaiton(hasNotify);
  }, [hasNotify]);

  return (
    <CompanyItemWrapper
      isSelected={isSelected}
      onClick={onSelectChat}
    >
      <CompanyTitle hasNotifications={hasNotify}>
        {room.buyerCompanyName}
      </CompanyTitle>

      {hasNotify && <MessageBadge />}

      <Indicator isVisible={isSelected} />
    </CompanyItemWrapper>
  );
};
