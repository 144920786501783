import { configureStore } from '@reduxjs/toolkit';

import filterReducer from './slices/filters.slice';
import messagesReducer from './slices/messages.slice';
import paginationScrollReducer from './slices/pagination-scroll.slice';
import roomVisitedReducer from './slices/room-visited.slice';

const store = configureStore({
  reducer: {
    filter: filterReducer,
    roomVisited: roomVisitedReducer,
    messages: messagesReducer,
    paginationScroll: paginationScrollReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
