import { FC } from 'react';

import { ArrowsDownIcon } from '@/components/ui/icons';

import { ButtonWrapper } from './styles';

interface ScrollDownButtonProps {
  isVisible: boolean;
  onClick: () => void;
}

export const ScrollDown: FC<ScrollDownButtonProps> = (props) => {
  const { isVisible, onClick } = props;

  return (
    <ButtonWrapper
      isVisible={isVisible}
      onClick={onClick}
    >
      <ArrowsDownIcon />
    </ButtonWrapper>
  );
};
