import { createContext } from 'react';

export interface ChatOpenedContextProps {
  openedLot?: string;
  openedAuction?: string;
}

export const ChatOpenedContext = createContext<ChatOpenedContextProps>({
  openedLot: undefined,
  openedAuction: undefined,
});
