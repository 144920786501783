import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AUCTION_ENUM_TYPE } from '@/types/auction';

import { filterSelector } from '@/store/selectors/filters.selector';
import { toggleFilter } from '@/store/slices/filters.slice';

import { Checkbox } from '@/components/ui/inputs';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { CheckboxWrapper, Text } from '../../styles';

interface CheckboxItemProps {
  value: string;
  title: string;
}

export const CheckboxItem: FC<CheckboxItemProps> = (props) => {
  const { value, title } = props;
  const dispatch = useAppDispatch();
  const filters = useAppSelector(filterSelector);
  const { t } = useTranslation();

  const changeoFilter = () => {
    dispatch(toggleFilter(value as AUCTION_ENUM_TYPE));
  };

  return (
    <CheckboxWrapper>
      <Checkbox
        value={filters[value as AUCTION_ENUM_TYPE]}
        onChange={changeoFilter}
      />

      <Text onClick={changeoFilter}>{t(title)}</Text>
    </CheckboxWrapper>
  );
};
