import axios, { AxiosRequestConfig } from 'axios';

import { getEnv } from '@/helpers/getEnv';

export const AUTHORIZATION = 'Authorization';

const setBasicApiConfig = async (config: AxiosRequestConfig) => {
  const token = localStorage.getItem('bidfinance-jwt');

  if (token && config.headers) {
    config.headers[AUTHORIZATION] = `Bearer ${token}`;
  }

  return config;
};

const config: AxiosRequestConfig = {
  baseURL: getEnv().HTTP_SERVER_URL,
};

axios.interceptors.request.use(setBasicApiConfig as any);

export const axiosInstance = axios.create(config);
