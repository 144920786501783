import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ISellerAuction, ILot } from '@/types/auction';
import { IRoom } from '@/types/chat';

import { LOT_NUMBERS } from '@/constants/auction';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';
import { messagesReadSelector } from '@/store/selectors/messages-selector';
import { roomsSelector } from '@/store/selectors/rooms.selector';
import { setCurrentRoom } from '@/store/slices/messages.slice';

import { MessageBadge } from '@/components/ui/badges';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { CompanyTitle } from '../../styles';

import { BuyersLotItemWrapper, Indicator } from './styles';
import { useAuthContext } from '@/contexts';
import { useChatOpenedContext } from '@/contexts';

interface CompanyItemProps {
  auction: ISellerAuction;
  lot: ILot;
}

export const BuyersLotItem: FC<CompanyItemProps> = (props) => {
  const { lot, auction } = props;
  const { openedLot } = useChatOpenedContext();

  const dispatch = useAppDispatch();
  const currentChat = useAppSelector(currentRoomSelector);
  const readMessages = useAppSelector(messagesReadSelector);
  const rooms = useAppSelector(roomsSelector);
  const { companyUuid } = useAuthContext();

  const { t } = useTranslation();

  const room = rooms.find(
    (room: IRoom) =>
      room.lotUuid === lot.lotUuid && room.buyerCompanyUuid === companyUuid
  );
  const isSelected = currentChat?.id === room?.id;

  const hasNotify =
    !!readMessages?.[companyUuid]?.[room!.auctionUuid]?.[room!.lotUuid]?.[2];

  const onSelectChat = () => {
    dispatch(setCurrentRoom(room as IRoom));
  };

  useEffect(() => {
    if (lot.lotUuid === openedLot) {
      onSelectChat();
    }
  }, [openedLot]);

  return (
    <BuyersLotItemWrapper
      isSelected={isSelected}
      onClick={onSelectChat}
    >
      <CompanyTitle hasNotifications={hasNotify}>
        {lot.lotNumber === LOT_NUMBERS.ALL_CASE
          ? t('common.all_case')
          : t('common.case_part')}{' '}
        #{auction.auctionNumber}.{lot.lotNumber}
        {hasNotify && <MessageBadge />}
      </CompanyTitle>

      <Indicator isVisible={isSelected} />
    </BuyersLotItemWrapper>
  );
};
