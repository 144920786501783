import { useEffect, useRef } from 'react';

export const useWatch = (callback: () => void, deps: any[] = []) => {
  const loadCount = useRef<number>(0);

  useEffect(() => {
    if (loadCount.current > 0) {
      return callback();
    }

    loadCount.current++;
  }, deps);
};
