export const NoChatsSelectedIcon = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90Z"
        fill="#DEE7FF"
      />
      <path
        d="M71.875 4H18.125C16.3991 4 15 5.12046 15 6.50261V21.5183C15 22.9004 16.3991 24.0209 18.125 24.0209H71.875C73.6009 24.0209 75 22.9004 75 21.5183V6.50261C75 5.12046 73.6009 4 71.875 4Z"
        fill="#618DFF"
      />
      <path
        d="M38.7499 9.50574H22.4999C21.4643 9.50574 20.6249 10.178 20.6249 11.0073C20.6249 11.8366 21.4643 12.5089 22.4999 12.5089H38.7499C39.7854 12.5089 40.6249 11.8366 40.6249 11.0073C40.6249 10.178 39.7854 9.50574 38.7499 9.50574Z"
        fill="#B4DAFF"
      />
      <path
        d="M49.9999 16.0126H22.4999C21.4643 16.0126 20.6249 16.6848 20.6249 17.5141C20.6249 18.3434 21.4643 19.0157 22.4999 19.0157H49.9999C51.0354 19.0157 51.8749 18.3434 51.8749 17.5141C51.8749 16.6848 51.0354 16.0126 49.9999 16.0126Z"
        fill="white"
      />
      <g filter="url(#filter0_d_286_45885)">
        <path
          d="M71.875 28.021H18.125C16.3991 28.021 15 29.1415 15 30.5236V83.0785C15 84.4606 16.3991 85.5811 18.125 85.5811H71.875C73.6009 85.5811 75 84.4606 75 83.0785V30.5236C75 29.1415 73.6009 28.021 71.875 28.021Z"
          fill="white"
        />
      </g>
      <path
        d="M37.5339 35.5288H24.5204C23.6911 35.5288 23.0188 36.2011 23.0188 37.0304C23.0188 37.8597 23.6911 38.5319 24.5204 38.5319H37.5339C38.3632 38.5319 39.0355 37.8597 39.0355 37.0304C39.0355 36.2011 38.3632 35.5288 37.5339 35.5288Z"
        fill="#B4DAFF"
      />
      <path
        d="M46.5434 42.0356H24.5204C23.6911 42.0356 23.0188 42.7079 23.0188 43.5372C23.0188 44.3665 23.6911 45.0388 24.5204 45.0388H46.5434C47.3726 45.0388 48.0449 44.3665 48.0449 43.5372C48.0449 42.7079 47.3726 42.0356 46.5434 42.0356Z"
        fill="#F1F4F6"
      />
      <path
        d="M37.5339 49.0428H24.5204C23.6911 49.0428 23.0188 49.7151 23.0188 50.5444C23.0188 51.3737 23.6911 52.046 24.5204 52.046H37.5339C38.3632 52.046 39.0355 51.3737 39.0355 50.5444C39.0355 49.7151 38.3632 49.0428 37.5339 49.0428Z"
        fill="#B4DAFF"
      />
      <path
        d="M46.5434 55.5497H24.5204C23.6911 55.5497 23.0188 56.222 23.0188 57.0512C23.0188 57.8805 23.6911 58.5528 24.5204 58.5528H46.5434C47.3726 58.5528 48.0449 57.8805 48.0449 57.0512C48.0449 56.222 47.3726 55.5497 46.5434 55.5497Z"
        fill="#F1F4F6"
      />
      <path
        d="M37.5339 62.5571H24.5204C23.6911 62.5571 23.0188 63.2294 23.0188 64.0587C23.0188 64.888 23.6911 65.5603 24.5204 65.5603H37.5339C38.3632 65.5603 39.0355 64.888 39.0355 64.0587C39.0355 63.2294 38.3632 62.5571 37.5339 62.5571Z"
        fill="#B4DAFF"
      />
      <path
        d="M46.5434 69.0638H24.5204C23.6911 69.0638 23.0188 69.7361 23.0188 70.5654C23.0188 71.3947 23.6911 72.067 24.5204 72.067H46.5434C47.3726 72.067 48.0449 71.3947 48.0449 70.5654C48.0449 69.7361 47.3726 69.0638 46.5434 69.0638Z"
        fill="#F1F4F6"
      />
      <defs>
        <filter
          id="filter0_d_286_45885"
          x="9"
          y="19.021"
          width="72"
          height="69.5601"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_286_45885"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_286_45885"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
