import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pl from './resources/pl.json';

export const initTranslation = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        pl: {
          translation: pl,
        },
      },
      lng: 'pl',
      fallbackLng: 'pl',

      interpolation: {
        escapeValue: false,
      },
    });
};
