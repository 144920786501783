import styled from 'styled-components';

import { DefaultScrollbarContainer } from '@/components/styles/containers';

interface HasNotification {
  hasNotifications: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 0.8px solid;
  border-color: #e7e8e9;
`;

export const Wrapper = styled(DefaultScrollbarContainer)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-bottom: 0px;
  border-right: 0px;
  width: 180px;
  overflow: scroll;
  height: calc(460px - 48.5px);
  padding-top: 4px;
  overflow: overlay;
`;

export const AuctionTitle = styled.div<HasNotification>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => (props.hasNotifications ? 700 : 500)};
  font-size: 10px;
  line-height: 150%;

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LotTitle = styled.div<HasNotification>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => (props.hasNotifications ? 700 : 500)};
  font-size: 10px;
  line-height: 150%;
`;

export const CompanyTitle = styled.div<HasNotification>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => (props.hasNotifications ? 700 : 500)};
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding-left: 4px;
`;

export const AuctionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
`;

export const AuctionTypeTitle = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding-left: 4px;
  color: ${(props) => props.color};
  margin-bottom: 4px;
  opacity: 0.7;
  font-weight: 600;
`;
