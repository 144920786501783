import { FC } from 'react';

import { PropsWithChildren } from '@/types/helpers';

import { Wrapper, Text } from './styles';

interface IconButtonProps extends PropsWithChildren {
  disabled?: boolean;
  color?: 'white' | 'blue';
  onClick?: () => void;
}

export const FilledButton: FC<IconButtonProps> = (props) => {
  const { children, disabled = false, onClick, color = 'blue' } = props;

  return (
    <Wrapper
      color={color}
      disabled={disabled}
      onClick={onClick}
    >
      <Text color={color}>{children}</Text>
    </Wrapper>
  );
};
