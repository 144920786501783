import { ChangeEvent } from 'react';

import { filterSearchSelector } from '@/store/selectors/filters.selector';
import { setSearch } from '@/store/slices/filters.slice';

import { SearchIcon } from '@/components/ui/icons';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { Input, SearchInputWrapper } from './styles';

export const Search = () => {
  const search = useAppSelector(filterSearchSelector);
  const dispatch = useAppDispatch();

  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(target.value));
  };

  return (
    <SearchInputWrapper>
      <SearchIcon />

      <Input
        value={search}
        onChange={onChange}
        placeholder="Search..."
      />
    </SearchInputWrapper>
  );
};
