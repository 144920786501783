import styled from 'styled-components';

import { Hideble } from '@/components/styles/containers';

export const Wrapper = styled(Hideble)`
  background: #ffffff;
  border: 1px solid #e7e8e9;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px 12px;
  width: 148px;
  position: absolute;
  right: 0px;
  z-index: 2;
  margin-top: 11px;
`;

export const Separator = styled.div`
  background: #e7e8e9;
  margin: 0px 2px;
  height: 1px;
  margin: 10px 0px;
`;

export const Text = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  color: #15171f;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ToggleWrapper = styled(CheckboxWrapper)`
  gap: 6px;
`;

export const ListWrapper = styled.div`
  position: relative;
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
