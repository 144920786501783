export const WhiteArrow = () => {
  return (
    <svg
      width="11"
      height="5"
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 4.69995L3 0.699951H11L0.5 4.69995Z"
        fill="white"
      />
    </svg>
  );
};
