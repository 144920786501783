import { FC } from 'react';

interface ArrowDownIconProps {
  fillColor?: string;
}

export const ArrowDownIcon: FC<ArrowDownIconProps> = (props) => {
  const { fillColor = '#272E39' } = props;

  return (
    <svg
      width="4"
      height="8"
      viewBox="0 0 4 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.77011 3.64502L0.940113 0.820018C0.893632 0.773154 0.838331 0.735957 0.777401 0.710573C0.716472 0.685188 0.651119 0.672119 0.585113 0.672119C0.519107 0.672119 0.453754 0.685188 0.392825 0.710573C0.331895 0.735957 0.276595 0.773154 0.230113 0.820018C0.136988 0.9137 0.0847168 1.04043 0.0847168 1.17252C0.0847168 1.30461 0.136988 1.43134 0.230113 1.52502L2.70511 4.02502L0.230113 6.50002C0.136988 6.5937 0.0847168 6.72043 0.0847168 6.85252C0.0847168 6.98461 0.136988 7.11134 0.230113 7.20502C0.27642 7.25226 0.331643 7.28985 0.392581 7.3156C0.453518 7.34134 0.518959 7.35474 0.585113 7.35502C0.651267 7.35474 0.716708 7.34134 0.777646 7.3156C0.838583 7.28985 0.893806 7.25226 0.940113 7.20502L3.77011 4.38002C3.82087 4.3332 3.86137 4.27637 3.88907 4.21312C3.91678 4.14987 3.93108 4.08157 3.93108 4.01252C3.93108 3.94347 3.91678 3.87516 3.88907 3.81192C3.86137 3.74867 3.82087 3.69184 3.77011 3.64502Z"
        fill={fillColor}
      />
    </svg>
  );
};
