import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';

import { useAppSelector } from '@/hooks/redux';

import {
  CompanyName,
  LotName,
  AuctionName,
  Wrapper,
  RoomName,
  TextWrapper,
} from './styles';
import { useAuthContext } from '@/contexts';

interface ChatHeaderProps {}

export const ChatHeader: FC<ChatHeaderProps> = () => {
  const { t } = useTranslation();
  const { companyUuid } = useAuthContext();

  const currentRoom = useAppSelector(currentRoomSelector);

  const isSeller = currentRoom?.sellerCompanyUuid === companyUuid;

  return (
    <Wrapper>
      <TextWrapper>
        <AuctionName>
          {t('common.auction')} #{currentRoom?.auctionNumber}
        </AuctionName>

        <RoomName>
          <CompanyName>
            {isSeller
              ? currentRoom?.buyerCompanyName
              : currentRoom?.sellerCompanyName}{' '}
            /{' '}
          </CompanyName>

          <LotName>
            {t('common.part')} #{currentRoom?.auctionNumber}.
            {currentRoom?.lotNumber}
          </LotName>
        </RoomName>
      </TextWrapper>

      {/* <IconButton icon={<MenuDotsIcon />} /> */}
    </Wrapper>
  );
};
