import styled from 'styled-components';

import { Hideble } from '@/components/styles/containers';

export const Wrapper = styled.div`
  padding: 0px 8px;
  gap: 10px;

  position: absolute;
  height: 16px;

  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(1.5px);
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
`;

export const Text = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 16px;
  color: #9298a2;
`;

export const Container = styled(Hideble)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 12px;
  position: sticky;

  top: 0px;
`;
