export const SearchIcon = () => {
  return (
    <svg
      width="12"
      style={{ minWidth: 12 }}
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14543 7.01406C6.35917 7.64221 5.36224 7.94546 4.35937 7.86153C3.35651 7.7776 2.42385 7.31287 1.75293 6.56277C1.08201 5.81267 0.723766 4.83415 0.751771 3.82818C0.779775 2.8222 1.1919 1.86512 1.90351 1.15351C2.61512 0.441903 3.5722 0.0297752 4.57818 0.00177051C5.58415 -0.0262341 6.56267 0.33201 7.31277 1.00293C8.06287 1.67385 8.5276 2.60651 8.61153 3.60937C8.69546 4.61224 8.39221 5.60917 7.76406 6.39543L11.1223 9.75281C11.2045 9.83496 11.2506 9.94638 11.2506 10.0626C11.2506 10.1787 11.2045 10.2902 11.1223 10.3723C11.0402 10.4545 10.9287 10.5006 10.8126 10.5006C10.6964 10.5006 10.585 10.4545 10.5028 10.3723L7.14631 7.01406H7.14543ZM7.75006 3.93756C7.75006 3.53538 7.67084 3.13715 7.51694 2.76559C7.36303 2.39403 7.13745 2.05642 6.85307 1.77204C6.56869 1.48766 6.23108 1.26208 5.85952 1.10818C5.48796 0.95427 5.08973 0.875056 4.68756 0.875056C4.28538 0.875056 3.88715 0.95427 3.51559 1.10818C3.14403 1.26208 2.80642 1.48766 2.52204 1.77204C2.23766 2.05642 2.01208 2.39403 1.85818 2.76559C1.70427 3.13715 1.62506 3.53538 1.62506 3.93756C1.62506 4.74978 1.94771 5.52874 2.52204 6.10307C3.09637 6.6774 3.87533 7.00006 4.68756 7.00006C5.49978 7.00006 6.27874 6.6774 6.85307 6.10307C7.4274 5.52874 7.75006 4.74978 7.75006 3.93756Z"
        fill="#C2C2C2"
      />
    </svg>
  );
};
