import { useState, useEffect } from 'react';

import { NOT_ALLOWED_PATHS } from './utils';

export const useAdmiAccess = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasUser, setHasUser] = useState<boolean>(false);
  const [isLandingPage, setLandingPage] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const isAdmin = localStorage.getItem('is-bidfinance-admin') === 'true';
      const pathname = window.location.pathname;

      const isLandingPage = NOT_ALLOWED_PATHS[pathname];
      const hasUser =
        !!localStorage.getItem('user-company-uuid') &&
        localStorage.getItem('user-company-uuid') !== 'undefined';

      setLandingPage(isLandingPage);
      setIsAdmin(isAdmin);
      setHasUser(hasUser);
    }, 250);

    return () => clearInterval(interval);
  }, []);

  const computeAccess = () => {
    const hasUser =
      !!localStorage.getItem('user-company-uuid') &&
      localStorage.getItem('user-company-uuid') !== 'undefined';

    const isAdmin = localStorage.getItem('is-bidfinance-admin') === 'true';

    return { isAdmin, hasUser };
  };

  return { isAdmin, hasUser, isLandingPage, computeAccess };
};
