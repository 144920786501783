import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  UpdateLatestMessagePayload,
  IMessage,
  IRoom,
  IIncomingMessage,
  INotificationHistory,
} from '@/types/chat';
import { IObject, KeyValuePair } from '@/types/helpers';

import { GET_HISTORY_STATUSES } from '@/constants/history';
import { LS_CONSTS, UNDEFINED } from '@/constants/localStorage';

const getSeconds = (): number => Math.floor(Date.now());

let addedRooms: IObject<number> = {};

interface InitialStateProps {
  messages: IObject<IMessage[]>;
  messagesRead: any;
  trigger: boolean;

  currentRoom: IRoom | null;
  rooms: IRoom[];
  isLoading: boolean;
  appearedMessages: IObject<boolean>;
  lastTimeUpdated: number;
}

const initialState: InitialStateProps = {
  messages: {},
  messagesRead: JSON.parse(
    localStorage.getItem(LS_CONSTS.MESSAGES_READ) || '{}'
  ),
  trigger: false,

  currentRoom: null,
  rooms: [],
  isLoading: true,
  appearedMessages: {},
  lastTimeUpdated: Number(
    localStorage.getItem(LS_CONSTS.LAST_TIME_UPDATE) || 0
  ),
};

const slice = createSlice({
  name: 'messages-slice',
  initialState,

  reducers: {
    addMessage: (
      state,
      { payload }: PayloadAction<KeyValuePair<IMessage, number>>
    ) => {
      const companyUuid =
        localStorage.getItem(LS_CONSTS.USER_COMPANY_UUID) || '';

      const getMessageIds = () =>
        (state.messages[payload.key] || []).map((item) => item.id);
      const maxId = Math.max(...getMessageIds());

      const currentRoomId = state.currentRoom?.id;
      const read = state.messagesRead;
      const room = (state.rooms.find(
        (room) => room?.id === payload.value.roomId
      ) || {}) as IRoom;

      const { id: messageId, roomId } = payload.value;

      const auctionUuid = payload.value?.auctionUuid || room?.auctionUuid;
      const lotUuid = payload.value.lotUuid || room?.lotUuid;

      const buyerCompanyUuid =
        payload.value.buyerCompanyUuid || room?.buyerCompanyUuid;
      const sellerCompanyUuid =
        payload.value.sellerCompanyUuid || room?.sellerCompanyUuid;

      const isSeller = sellerCompanyUuid === companyUuid;

      if (!read[companyUuid]) {
        read[companyUuid] = {};
      }

      if (!read[companyUuid][auctionUuid]) {
        read[companyUuid][auctionUuid] = {};
      }

      const notBylotUuid = !read[companyUuid][auctionUuid][lotUuid];

      if (notBylotUuid && isSeller) {
        read[companyUuid][auctionUuid][lotUuid] = {};
      } else if (notBylotUuid) {
        read[companyUuid][auctionUuid][lotUuid] = [0, 0, 0];
      }

      if (
        !read[companyUuid][auctionUuid][lotUuid][buyerCompanyUuid] &&
        isSeller
      ) {
        read[companyUuid][auctionUuid][lotUuid][buyerCompanyUuid] = [0, 0, 0];
      }

      const notifications = isSeller
        ? read[companyUuid][auctionUuid][lotUuid][buyerCompanyUuid]
        : read[companyUuid][auctionUuid][lotUuid];

      const currentLastMessage = notifications?.[0] || 0;

      if (messageId >= maxId && room) {
        state.messages[roomId] = (state.messages[roomId] || []).concat([
          payload.value,
        ]);

        state.trigger = true;
      } else if (room) {
        state.messages[roomId] = [payload.value].concat(
          state.messages[roomId] || []
        );

        state.trigger = false;
      }

      const incrementCounter =
        currentRoomId !== roomId &&
        !state.appearedMessages[messageId] &&
        currentLastMessage < messageId;

      if (incrementCounter && auctionUuid) {
        notifications[2]++;
        notifications[0] = messageId;
      }

      const lastReadMessage = notifications[1];

      const decrementCoutner =
        roomId === currentRoomId && lastReadMessage <= messageId;

      if (decrementCoutner && auctionUuid) {
        notifications[1] = messageId;
        notifications[0] = messageId;
        const counter = notifications[2];

        if (counter) {
          notifications[2]--;
        }
      }

      state.appearedMessages[messageId] = true;
      localStorage.setItem(LS_CONSTS.MESSAGES_READ, JSON.stringify(read));

      state.lastTimeUpdated = getSeconds();
      localStorage.setItem(
        LS_CONSTS.LAST_TIME_UPDATE,
        state.lastTimeUpdated.toString()
      );
    },

    changeTrigger: (state, { payload }: PayloadAction<boolean>) => {
      state.trigger = payload;
    },

    setCurrentRoom: (state, { payload }: PayloadAction<IRoom>) => {
      state.currentRoom = payload;
    },

    setRooms: (state, { payload }: PayloadAction<IRoom[]>) => {
      state.rooms = payload;
    },

    addNewRoom: (state, { payload }: PayloadAction<IRoom>) => {
      if (payload && !addedRooms[payload.id]) {
        state.rooms = [...state.rooms, payload];
        addedRooms[payload.id] = 1;
      }
    },

    setRoomsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },

    removeCurrentRoom: (state) => {
      state.currentRoom = null;
    },

    updateLastReadMessage: (
      state,
      { payload }: PayloadAction<UpdateLatestMessagePayload>
    ) => {
      const companyUuid =
        localStorage.getItem(LS_CONSTS.USER_COMPANY_UUID) || '';
      const { isSeller, auctionUuid, lotUuid, value, buyerCompanyUuid } =
        payload || {};
      const read = state.messagesRead;
      const key = lotUuid;

      if (!read[companyUuid]) {
        read[companyUuid] = {};
      }

      if (!read[companyUuid][auctionUuid]) {
        read[companyUuid][auctionUuid] = {};
      }

      const noBylotUuid = !read[companyUuid][auctionUuid][key];

      if (noBylotUuid && isSeller) {
        read[companyUuid][auctionUuid][key] = {};
      } else if (noBylotUuid) {
        read[companyUuid][auctionUuid][key] = [0, 0, 0];
      }

      if (!read[companyUuid][auctionUuid][key][buyerCompanyUuid] && isSeller) {
        read[companyUuid][auctionUuid][key][buyerCompanyUuid] = [0, 0, 0];
      }

      const notifications = isSeller
        ? read[companyUuid][auctionUuid][lotUuid][buyerCompanyUuid]
        : read[companyUuid][auctionUuid][lotUuid];

      const lastReadMessage = notifications[0];

      if (lastReadMessage <= payload.value) {
        notifications[1] = value;
        notifications[0] = value;
      }

      if (auctionUuid) {
        const counter = notifications[2];

        if (counter) {
          notifications[2]--;

          state.lastTimeUpdated = getSeconds();
          localStorage.setItem(
            LS_CONSTS.LAST_TIME_UPDATE,
            state.lastTimeUpdated.toString()
          );
        }
      }

      const [incomingMessage, readMessage] = notifications;

      if (incomingMessage <= readMessage) {
        notifications[2] = 0;
      }

      localStorage.setItem(LS_CONSTS.MESSAGES_READ, JSON.stringify(read));
    },

    incrementUnredCounter: (
      state,
      { payload }: PayloadAction<IIncomingMessage>
    ) => {
      const companyUuid =
        localStorage.getItem(LS_CONSTS.USER_COMPANY_UUID) || '';
      const read = state.messagesRead;
      const { auctionUuid, lotUuid, isSeller, buyerCompanyUuid } =
        payload || {};
      const key = lotUuid;

      if (!key || key === UNDEFINED) {
        return;
      }

      if (!read[companyUuid]) {
        read[companyUuid] = {};
      }

      if (!read[companyUuid][auctionUuid]) {
        read[companyUuid][auctionUuid] = {};
      }

      const noBylotUuid = !read[companyUuid][auctionUuid][key];

      if (noBylotUuid && isSeller) {
        read[companyUuid][auctionUuid][key] = {};
      } else if (noBylotUuid) {
        read[companyUuid][auctionUuid][key] = [0, 0, 0];
      }

      if (!read[companyUuid][auctionUuid][key][buyerCompanyUuid] && isSeller) {
        read[companyUuid][auctionUuid][key][buyerCompanyUuid] = [0, 0, 0];
      }

      const notifications = isSeller
        ? read[companyUuid][auctionUuid][lotUuid][buyerCompanyUuid]
        : read[companyUuid][auctionUuid][lotUuid];

      const currentLastMessage = notifications[0];
      const moreThanMessage = currentLastMessage < payload.id;

      if (moreThanMessage) {
        notifications[2]++;
        notifications[0] = payload.id;
      }

      localStorage.setItem(LS_CONSTS.MESSAGES_READ, JSON.stringify(read));
    },

    updateNotificationHistory: (
      state,
      { payload }: PayloadAction<{ data: INotificationHistory; status: string }>
    ) => {
      const { data, status } = payload;

      const isValidData =
        data.content && typeof JSON.parse(data.content) === 'object';
      const isGet = status === GET_HISTORY_STATUSES.GET;

      if (isValidData) {
        state.messagesRead[data.companyUuid] = isGet
          ? JSON.parse(data.content)
          : {};
      }

      localStorage.setItem(
        LS_CONSTS.MESSAGES_READ,
        JSON.stringify(state.messagesRead)
      );
    },

    clearRooms: (state) => {
      state.rooms = [];
      addedRooms = {};
    },

    reset: (state) => {
      state.currentRoom = null;
      state.messages = {};
      addedRooms = {};
      state.messagesRead = JSON.parse(
        localStorage.getItem(LS_CONSTS.MESSAGES_READ) || '{}'
      );
      state.rooms = [];

      state.isLoading = true;
    },
  },
});

export default slice.reducer;

export const {
  addMessage,
  changeTrigger,
  setCurrentRoom,
  setRooms,
  addNewRoom,
  setRoomsLoading,
  removeCurrentRoom,
  updateLastReadMessage,
  incrementUnredCounter,
  clearRooms,
  updateNotificationHistory,
  reset,
} = slice.actions;
