import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialStateProps {
  FINISHED: boolean;
  IN_PROGRESS: boolean;
  SCHEDULED: boolean;
  COMPLETED: boolean;
  NOT_READ: boolean;
  isSeller: boolean;
  search: string;
}

const initialState: InitialStateProps = localStorage.getItem('chat-filters')
  ? JSON.parse(localStorage.getItem('chat-filters') || '{}')
  : {
      FINISHED: true,
      COMPLETED: false,
      IN_PROGRESS: true,
      NOT_READ: true,
      SCHEDULED: true,
      isSeller: false,
      search: '',
    };

export type FILTER_KEY_TYPE = keyof InitialStateProps;

export const slice = createSlice({
  name: 'filters-slice',
  initialState,
  reducers: {
    toggleFilter: (state, action: PayloadAction<keyof InitialStateProps>) => {
      (state[action.payload] as boolean) = !state[action.payload];

      localStorage.setItem('chat-filters', JSON.stringify(state));
    },

    toggleIsSeller: (state) => {
      state.isSeller = !state.isSeller;

      localStorage.setItem('chat-filters', JSON.stringify(state));
    },

    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;

      localStorage.setItem('chat-filters', JSON.stringify(state));
    },
    reset: (state) => {
      localStorage.removeItem('chat-filters');
      state.FINISHED = true;
      state.IN_PROGRESS = true;
      state.NOT_READ = true;
      state.SCHEDULED = true;
      state.COMPLETED = false;
      state.search = '';
    },
  },
});

export const { toggleFilter, toggleIsSeller, setSearch, reset } = slice.actions;

export default slice.reducer;
