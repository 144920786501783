import styled from 'styled-components';

import { Hideble } from '@/components/styles/containers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 9px;

  background: #0496ff;
  border: 1px solid #ffffff;

  box-shadow: 0px 1.8px 10px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.95;
  }

  &:active {
    transform: scale(0.97);
  }
`;

export const ChatButtonWrapper = styled(Hideble)`
  position: fixed;
  bottom: 15px;
  right: 15px;
  transition: 0.3s all ease;
`;

export const Text = styled.div`
  color: #fff;
  font-weight: 600;
  margin-top: 12px;
  text-align: center;
  max-width: 112px;
  font-family: 'Lato';
  font-size: 9px;
  line-height: 170%;
`;
