import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('dizair-chat-root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
