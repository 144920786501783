import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/store';

export const roomsLoadingSelector = createSelector(
  (state: RootState) => state.messages,
  (rooms) => rooms.isLoading
);

export const roomsSelector = createSelector(
  (state: RootState) => state.messages,
  (rooms) => rooms.rooms
);
