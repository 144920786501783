import styled from 'styled-components';

interface WrapperProps {
  disabled: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 99999px;
  cursor: pointer;
  padding: 5px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f3f4f6;
  }

  &:active {
    transform: scale(0.9);
  }
`;
