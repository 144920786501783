import { useEffect, useState } from 'react';

import { Text, Wrapper } from './styles';
import { useAuthContext } from '@/contexts';

export const Badge = () => {
  const [messages, setMessages] = useState<number>(0);
  const { user } = useAuthContext();

  useEffect(() => {
    const interval = setInterval(() => {
      if (user) {
        const { sub } = user;

        const companyUuid = localStorage.getItem('user-company-uuid');
        const isSeller = localStorage.getItem('show-mine-' + sub) === 'true';

        const userReadMessages =
          JSON.parse(localStorage.getItem('messages-read') || '{}')[
            companyUuid as string
          ] || {};

        const aggMessages = Object.values(userReadMessages).flatMap(
          (item: any) => Object.values(item)
        );

        const messagesData = isSeller
          ? aggMessages.flatMap((el: any) =>
              Array.isArray(el) ? [] : Object.values(el)
            )
          : aggMessages
              .map((el: any) => (Array.isArray(el) ? el : null))
              .filter(Boolean)
              .map((item) => (Array.isArray(item?.[0]) ? item?.flat() : item));

        const messages = messagesData
          .filter((item) => typeof item === 'object' && !!item)
          .reduce((acc, item: any) => acc + (item ? item[2] : 0), 0);

        setMessages(messages as number);
      }
    }, 300);

    return () => clearInterval(interval);
  }, [user]);

  if (!messages) {
    return <></>;
  }

  return (
    <Wrapper>
      <Text twoDigits={(messages | 0) > 9}>
        {messages > 99 ? '+99' : messages}
      </Text>
    </Wrapper>
  );
};
