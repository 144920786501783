import { IObject } from '@/types/helpers';

export const AUCTION_STATUSES = {
  Scheduled: 'SCHEDULED',
  InProgress: 'IN_PROGRESS',
  Finished: 'FINISHED',
  Completed: 'COMPLETED',
};

export const AUCTION_COLORS = {
  [AUCTION_STATUSES.Finished]: '#525050',
  [AUCTION_STATUSES.Completed]: '#706e6e',
  [AUCTION_STATUSES.InProgress]: '#388668',
  [AUCTION_STATUSES.Scheduled]: '#b56a1f',
};

export const AUCTION_COLORS_LIGHT = {
  [AUCTION_STATUSES.Finished]: '#7D9EB54d',
  [AUCTION_STATUSES.Completed]: '#9797974d',
  [AUCTION_STATUSES.InProgress]: '#07C1804d',
  [AUCTION_STATUSES.Scheduled]: '#FF99314d',
};

export const AUCTION_SORTING_STATUSES: IObject<number> = {
  [AUCTION_STATUSES.Finished]: 3,
  [AUCTION_STATUSES.Completed]: 4,
  [AUCTION_STATUSES.Scheduled]: 2,
  [AUCTION_STATUSES.InProgress]: 1,
};

export const LOT_NUMBERS = {
  ALL_CASE: 0,
};
