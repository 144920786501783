import { FC } from 'react';

import { MessageBadgeWrapper, Wrapper } from './style';

interface MessageBadgeProps {
  color?: string;
  opacity?: number;
}

export const MessageBadge: FC<MessageBadgeProps> = (props) => {
  const { color = '#467AFF', opacity = 1 } = props;

  return (
    <Wrapper>
      <MessageBadgeWrapper
        opacity={opacity}
        backgroundColor={color}
      />
    </Wrapper>
  );
};
