import { FC, useRef, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISellerAuction } from '@/types/auction';
import { IObject } from '@/types/helpers';

import { AUCTION_COLORS } from '@/constants/auction';

import { MessageBadge } from '@/components/ui/badges';
import { Spoiler } from '@/components/ui/containers';

import { useAppSelector } from '@/hooks/redux';

import { Lot, BuyersLotItem } from '../';
import { AuctionTitle } from '../../styles';
import { useGetRoomNotified } from '../lot/utils';

import { LotsWrapper } from './style';
import { useChatOpenedContext } from '@/contexts';

interface AuctionProps {
  auction: ISellerAuction;
  isFirstIndex: boolean;
  isSeller: boolean;
}

export const Auction: FC<AuctionProps> = (props) => {
  const { auction, isFirstIndex, isSeller } = props;
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const { openedAuction, openedLot } = useChatOpenedContext();

  useEffect(() => {
    if (openedAuction === auction.auctionUuid) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      setTrigger(true);
    }
  }, [openedAuction, openedLot]);

  const rooms = useAppSelector((state) => {
    const dict: IObject<number> = {};

    return state.messages.rooms
      .filter((room) => room.auctionUuid === auction.auctionUuid)
      .filter((room) => {
        if (dict[room.id]) {
          return false;
        }

        dict[room.id] = 1;
        return true;
      });
  });

  const { hasNotifications } = useGetRoomNotified(rooms);

  return (
    <Spoiler
      ref={ref}
      trigger={trigger}
      borderTop={isFirstIndex}
      auctionStatus={auction.auctionStatus}
      hiddenContent={<>{hasNotifications && <MessageBadge opacity={0.8} />}</>}
      color={AUCTION_COLORS[auction.auctionStatus as string]}
      title={
        <AuctionTitle hasNotifications={hasNotifications}>
          {t('common.auction')} #{auction.auctionNumber}{' '}
          {auction.sellerCompanyName}
        </AuctionTitle>
      }
    >
      <LotsWrapper>
        {auction.lots.map((lot) =>
          isSeller ? (
            <Lot
              auction={auction}
              key={lot.lotUuid}
              lot={lot}
            />
          ) : (
            <BuyersLotItem
              key={lot.lotUuid}
              auction={auction}
              lot={lot}
            />
          )
        )}
      </LotsWrapper>
    </Spoiler>
  );
};
