import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AUCTION_STATUSES } from '@/constants/auction';
import { MESSAGE_MAX_LENGTH } from '@/constants/messages';
import { MESSAGE_TYPES } from '@/constants/websockets';

import { currentRoomSelector } from '@/store/selectors/curren-chat.selector';

import { FilledButton } from '@/components/ui/buttons';

import { useAppSelector } from '@/hooks/redux';

import {
  Input,
  ActionsWrapper,
  InputWrapper,
  Form,
  MaxSymbolText,
} from './styles';
import { useChatContext } from '@/contexts';
import { useInput } from '@/hooks';

export const SendMessage = () => {
  const { t } = useTranslation();
  const { bind, value, clear } = useInput();
  const { sendMessage: sendClientSessage } = useChatContext();
  const currentRoom = useAppSelector(currentRoomSelector);

  const isCompleted = currentRoom?.status === AUCTION_STATUSES.Completed;

  const sendMessage = () => {
    if (!value || isCompleted || value.length > MESSAGE_MAX_LENGTH) return;

    sendClientSessage(MESSAGE_TYPES.NEW_MESSAGE, {
      roomId: currentRoom?.id as number,
      message: value,
    });

    clear();
  };

  useEffect(() => {
    clear();
  }, [currentRoom]);

  return (
    <InputWrapper>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage();
        }}
      >
        <Input
          disabled={isCompleted}
          {...bind}
          placeholder={t('chatbox.write_a_message') as string}
        />

        <MaxSymbolText isError={value.length > MESSAGE_MAX_LENGTH}>
          {value.length} / {MESSAGE_MAX_LENGTH}
        </MaxSymbolText>
      </Form>

      <ActionsWrapper>
        {/* <AttachFile /> */}

        <FilledButton
          disabled={!value || isCompleted || value.length > MESSAGE_MAX_LENGTH}
          onClick={sendMessage}
        >
          {t('chatbox.send')}
        </FilledButton>
      </ActionsWrapper>
    </InputWrapper>
  );
};
