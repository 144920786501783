import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ISellerAuction } from '@/types/auction';
import { IObject } from '@/types/helpers';

import { getAuctionName } from '@/helpers/auction';

import { AUCTION_COLORS, AUCTION_SORTING_STATUSES } from '@/constants/auction';
import { MESSAGE_TYPES } from '@/constants/websockets';

import {
  filterSelector,
  filterSearchSelector,
} from '@/store/selectors/filters.selector';
import { roomsLoadingSelector } from '@/store/selectors/rooms.selector';
import { roomsSelector } from '@/store/selectors/rooms.selector';
import { clearRooms, setRoomsLoading } from '@/store/slices/messages.slice';
import { removeCurrentRoom } from '@/store/slices/messages.slice';

import { ChatsPreloader } from '@/components/ui/preloaders';

import { useAppSelector } from '@/hooks/redux';
import { useAppDispatch } from '@/hooks/redux';

import { Auction, SearchFilters, NotFoundAuctions } from './components';
import { useConvertRoomsToAuctions } from './helpers/convert-rooms-to-auctions';
import { useSellerOrBuyer } from './hooks';
import {
  Wrapper,
  Container,
  AuctionsWrapper,
  AuctionTypeTitle,
} from './styles';
import { useAuthContext, useChatContext } from '@/contexts';
import { useWatch } from '@/hooks';

export const Sidebar = () => {
  const data = useAppSelector(roomsSelector);
  const dispatch = useAppDispatch();
  const { companyUuid, user } = useAuthContext();
  const { auctions } = useConvertRoomsToAuctions(data);
  const { t } = useTranslation();
  const isLoading = useAppSelector(roomsLoadingSelector);
  const rooms = useAppSelector(roomsSelector);
  const { sendMessage } = useChatContext();

  const search = useAppSelector(filterSearchSelector);
  const filters = useAppSelector(filterSelector);

  const { isSeller } = useSellerOrBuyer();

  useEffect(() => {
    if (!user) {
      dispatch(setRoomsLoading(true));
    }
  }, [user]);

  useWatch(() => {
    setTimeout(() => dispatch(setRoomsLoading(false)), 1500);
  }, [rooms]);

  useEffect(() => {
    const interval = setInterval(() => {
      // dispatch(clearRooms());
      sendMessage(MESSAGE_TYPES.SYNC_ROOMS);
      // dispatch(setRoomsLoading(true));

      setTimeout(() => dispatch(setRoomsLoading(false)), 3500);
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(removeCurrentRoom);
  }, [isSeller]);

  const computedAuctions = useMemo(
    () =>
      auctions.filter((auction) => {
        const isSellerOrBuyer = isSeller
          ? auction.sellerCompanyUuid === companyUuid
          : auction.sellerCompanyUuid !== companyUuid;

        const isInSearch = getAuctionName(t, auction)
          .toLowerCase()
          .includes(search.toLowerCase());

        const statusMatch = filters[auction.auctionStatus];

        return isSellerOrBuyer && isInSearch && statusMatch;
      }),
    [auctions, filters, search, isSeller]
  );

  const auctionsObject = computedAuctions.reduce(
    (acc: IObject<ISellerAuction[]>, item) => ({
      ...acc,
      [item.auctionStatus]: [...(acc[item.auctionStatus] || []), item],
    }),
    {}
  );

  const sorted_statuses = [...Object.keys(auctionsObject)].sort(
    (a, b) => AUCTION_SORTING_STATUSES[a] - AUCTION_SORTING_STATUSES[b]
  );

  const Auctions = sorted_statuses.map((key: string) => {
    return (
      <AuctionsWrapper key={key}>
        <AuctionTypeTitle color={AUCTION_COLORS[key]}>
          {t(`sidebar.auction_statuses.${key}`)}
        </AuctionTypeTitle>

        {[...auctionsObject[key]]
          .sort((a, b) => b.auctionNumber - a.auctionNumber)
          .map((auction, index) => (
            <Auction
              isSeller={isSeller as boolean}
              isFirstIndex={index === 0}
              auction={auction}
              key={auction.auctionUuid}
            />
          ))}
      </AuctionsWrapper>
    );
  });

  const getContent = () => {
    if (isLoading) {
      return <ChatsPreloader />;
    } else if (!computedAuctions.length) {
      return <NotFoundAuctions />;
    }

    return Auctions;
  };

  return (
    <Container>
      <SearchFilters />

      <Wrapper>{getContent()}</Wrapper>
    </Container>
  );
};
