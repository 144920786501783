import { ChangeEvent, useState } from 'react';

export const useInput = (initialValue: string = '') => {
  const [value, setValue] = useState<string>(initialValue);

  const changeValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };

  const clear = () => setValue('');

  const bind = {
    onChange: changeValue,
    value,
  };

  return { bind, value, setValue, clear };
};
