import { createContext } from 'react';

import { IUser } from '@/types/auth';

export interface AuthContextProps {
  user: null | IUser;
  token: string;
  companyUuid: string;
}

export const AuthContext = createContext<AuthContextProps>({
  token: '',
  user: null,
  companyUuid: '',
});
