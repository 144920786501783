import { useEffect, useRef, useState } from 'react';

import { useAdmiAccess } from '@/pages/home/hooks';

import { GET_HISTORY_STATUSES } from '@/constants/history';
import { MESSAGE_TYPES } from '@/constants/websockets';

import { messagesSelector } from '@/store/selectors/messages-selector';
import {
  addMessage,
  updateNotificationHistory,
} from '@/store/slices/messages.slice';
import {
  addNewRoom,
  incrementUnredCounter,
} from '@/store/slices/messages.slice';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';

import { useAuthContext } from '../auth';

import { useEnv } from '@/hooks';
import { HistoryService } from '@/services';

export const useValue = () => {
  const clientRef = useRef<WebSocket | null>(null);
  const [isClosed, setClosed] = useState<boolean>(false);
  const [isConnected, setConnected] = useState<boolean>(false);
  const historyLocked = useRef<boolean>(false);
  const { WS_SERVER_URL } = useEnv();

  const isOpened = useRef<boolean>(false);

  const messages = useAppSelector(messagesSelector);
  const { computeAccess } = useAdmiAccess();

  const dispatch = useAppDispatch();
  const { user, token } = useAuthContext();
  const updateCounter = useRef<number>(0);
  const isError = useRef<boolean>(false);

  const resetClient = () => {
    const token = localStorage.getItem('bidfinance-jwt');
    clientRef.current = new WebSocket(`${WS_SERVER_URL}?jwt-token=` + token);
  };

  const attachEvents = () => {
    if (clientRef.current) {
      clientRef.current.onopen = () => {
        console.log('on open');
        isOpened.current = true;
        setConnected(true);
      };
      clientRef.current.onerror = (e) => {
        console.log('error', e);
        isError.current = true;
      };
      clientRef.current.onmessage = (message) => {
        const msg = JSON.parse(message.data);

        switch (msg.type) {
          case MESSAGE_TYPES.INCOMING_MESSAGE:
            dispatch(incrementUnredCounter(msg.data));
            break;
          case MESSAGE_TYPES.MESSAGE:
            const payload = {
              key: msg.data.roomId,
              value: msg.data,
            };

            dispatch(addMessage(payload));

            break;
          case MESSAGE_TYPES.ROOM:
            dispatch(addNewRoom(msg.data));
            break;
        }
      };

      clientRef.current.onclose = () => {
        const { isAdmin, hasUser } = computeAccess();
        isOpened.current = false;
        // if (!isError.current && !isAdmin && hasUser) {
        //   resetClient();
        //   attachEvents();
        // }
      };
    }
  };

  const fetchHistory = async () => {
    const { hasUser, isAdmin } = computeAccess();

    if (isAdmin || !hasUser) {
      return;
    }

    const response = await HistoryService.fetchCurrentHistory();
    dispatch(updateNotificationHistory(response));
  };

  const updateHistory = async () => {
    const { hasUser, isAdmin } = computeAccess();

    if (isAdmin || !hasUser || historyLocked.current) {
      return;
    }

    const history = await HistoryService.updateCurrentHistory();
    dispatch(
      updateNotificationHistory({
        data: history,
        status: GET_HISTORY_STATUSES.GET,
      })
    );
  };

  const lockHistory = () => {
    historyLocked.current = true;
  };

  const unlockHistory = () => {
    historyLocked.current = false;
  };

  useEffect(() => {
    if (!clientRef.current && token) {
      clientRef.current = new WebSocket(`${WS_SERVER_URL}?jwt-token=` + token);
      attachEvents();

      fetchHistory();
    }

    return () => {
      clientRef.current = null;
    };
  }, [token]);

  // useWatch(() => {
  //   clearTimeout(timer.current);

  //   timer.current = setTimeout(() => {
  //     updateHistory();
  //   }, 300);

  //   return () => clearTimeout(timer.current);
  // }, [messages]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { hasUser, isAdmin } = computeAccess();
      if (
        clientRef.current?.readyState === WebSocket.CLOSED &&
        hasUser &&
        !isAdmin
      ) {
        resetClient();
        attachEvents();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const { hasUser, isAdmin } = computeAccess();
      if (!isError.current && hasUser && !isAdmin && !isOpened.current) {
        isError.current = false;
        resetClient();
        attachEvents();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const { isAdmin } = computeAccess();
    if (user && !isAdmin) {
      if (updateCounter.current === 2) {
        clientRef?.current?.close();
        resetClient();
        attachEvents();
      } else {
        updateCounter.current++;
      }
    }
  }, [user]);

  useEffect(() => {
    const interval = setInterval(updateHistory, 100 * 1000);

    return () => {
      clearInterval(interval);
      if (updateCounter.current === 2) {
        updateHistory();
      }
    };
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (clientRef.current?.readyState === WebSocket.CLOSED) {
  //       console.log('websocket is not closed');
  //     }
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  const sendMessage = (type: string, data = {}) => {
    if (clientRef.current)
      clientRef.current.send(
        JSON.stringify({ type, ...(data ? { data } : {}) })
      );
  };

  return {
    client: clientRef.current,
    sendMessage,
    resetClient,
    isClosed,
    setClosed,
    isConnected,
    lockHistory,
    unlockHistory,
  };
};
