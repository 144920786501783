export const ArrowsDownIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166707 6.60417C0.166707 6.25695 0.316151 6.01056 0.61504 5.865C0.913373 5.71889 1.20143 5.74306 1.47921 5.9375L6.00004 9.16667L10.5209 5.9375C10.7987 5.74306 11.087 5.71889 11.3859 5.865C11.6842 6.01056 11.8334 6.25695 11.8334 6.60417C11.8334 6.75695 11.7953 6.89584 11.7192 7.02084C11.6425 7.14584 11.5487 7.25 11.4375 7.33334L6.47921 10.8542C6.40976 10.9097 6.33337 10.9514 6.25004 10.9792C6.16671 11.0069 6.08337 11.0208 6.00004 11.0208C5.91671 11.0208 5.83698 11.0069 5.76087 10.9792C5.68421 10.9514 5.60421 10.9097 5.52087 10.8542L0.56254 7.33333C0.451429 7.25 0.357539 7.14583 0.280872 7.02083C0.204761 6.89583 0.166707 6.75695 0.166707 6.60417ZM0.166707 1.64583C0.166707 1.29861 0.316151 1.05222 0.61504 0.906667C0.913374 0.760556 1.20143 0.784723 1.47921 0.979168L6.00004 4.20833L10.5209 0.979168C10.7987 0.784724 11.087 0.760557 11.3859 0.906668C11.6842 1.05222 11.8334 1.29861 11.8334 1.64583C11.8334 1.79861 11.7953 1.9375 11.7192 2.0625C11.6425 2.1875 11.5487 2.29167 11.4375 2.375L6.47921 5.89583C6.40976 5.95139 6.33337 5.99306 6.25004 6.02084C6.16671 6.04861 6.08337 6.0625 6.00004 6.0625C5.91671 6.0625 5.83337 6.04861 5.75004 6.02084C5.66671 5.99306 5.59032 5.95139 5.52087 5.89583L0.56254 2.375C0.451429 2.29167 0.357539 2.1875 0.280873 2.0625C0.204762 1.9375 0.166707 1.79861 0.166707 1.64583Z"
        fill="#467AFF"
      />
    </svg>
  );
};
