export const BlueArrow = () => {
  return (
    <svg
      width="11"
      height="5"
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4.30005L8 0.300049H0L10.5 4.30005Z"
        fill="#467AFF"
      />
    </svg>
  );
};
