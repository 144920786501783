import styled from 'styled-components';

interface HasOwner {
  isOwner: boolean;
}

export const Wrapper = styled.div<HasOwner>`
  display: flex;
  position: relative;
  flex-direction: column;
  word-break: break-all;
  margin-left: ${(props) => (props.isOwner ? 'auto' : 'none')};
  margin-right: ${(props) => (props.isOwner ? 'none' : 'auto')};
`;

export const BuyerName = styled.div<HasOwner>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 16px;
  color: #272e39;
  margin: ${(props) =>
    props.isOwner ? '0px 3px 0px auto' : '0px auto 0px 2px'};
`;

export const Text = styled.div<HasOwner>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;

  color: ${(props) => (props.isOwner ? '#fff' : '#272e39')};
`;

export const MessageWrapper = styled.div<HasOwner>`
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  background: ${(props) => (props.isOwner ? '#467AFF' : '#FFFFFF')};
  color: ${(props) => (props.isOwner ? '#fff' : '#223345')};
  border-radius: 10px;
  max-width: 344px;
  padding: 4px 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  max-width: 208px;
`;

export const Tail = styled.div<HasOwner>`
  left: ${(props) => (props.isOwner ? 'none' : '8px')};
  right: ${(props) => (props.isOwner ? '8px' : 'none')};
  position: absolute;

  bottom: -1.6px;
  height: 12px;
  border-color: ${(props) => (props.isOwner ? '#467AFF' : '#FFFFFF')};
`;
