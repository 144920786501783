import { FC, useContext } from 'react';

import { PropsWithChildren } from '@/types/helpers';

import { ChatOpenedContext, ChatOpenedContextProps } from './context';
import { useChatTriggers } from '@/hooks';

interface ChatProviderProps extends PropsWithChildren {}

export const ChatOpenedProvider: FC<ChatProviderProps> = (props) => {
  const { children } = props;
  const value = useChatTriggers();

  return (
    <ChatOpenedContext.Provider value={value}>
      {children}
    </ChatOpenedContext.Provider>
  );
};

export const useChatOpenedContext = () =>
  useContext<ChatOpenedContextProps>(ChatOpenedContext);
