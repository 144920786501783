import { IRoom } from '@/types/chat';

import { messagesReadSelector } from '@/store/selectors/messages-selector';

import { useAppSelector } from '@/hooks/redux';

import { useSellerOrBuyer } from '../../hooks';

import { useAuthContext } from '@/contexts';

export const useGetRoomNotified = (rooms: IRoom[]) => {
  const { companyUuid } = useAuthContext();
  const readMessages = useAppSelector(messagesReadSelector);
  const { isSeller } = useSellerOrBuyer();

  const getRoomNotified = (room: IRoom) => {
    const { lotUuid, auctionUuid, buyerCompanyUuid } = room;

    const response = readMessages?.[companyUuid]?.[auctionUuid]?.[lotUuid];

    if (isSeller) {
      return !!response?.[buyerCompanyUuid]?.[2];
    }

    return !!response?.[2];
  };

  const hasNotifications = rooms
    .map((room) => getRoomNotified(room))
    .includes(true);

  return {
    hasNotifications,
  };
};
