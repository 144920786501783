import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  background: #ffffff;
  height: 43px;
  border-bottom: 0.5px solid #e7e8e9;
`;
