import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChangeElementHeightPayload {
  key: number;
  value: number;
}

interface InitialStateProps {
  pagination: {
    [key: string]: number;
  };
}

const initialState: InitialStateProps = { pagination: {} };

const slice = createSlice({
  name: 'pagination-slice',
  initialState,
  reducers: {
    changeElementHeight: (
      state,
      { payload }: PayloadAction<ChangeElementHeightPayload>
    ) => {
      state.pagination[payload.key] = payload.value;
    },

    reset: (state) => {
      state.pagination = {};
    },
  },
});

export default slice.reducer;

export const { changeElementHeight, reset } = slice.actions;
