import styled from 'styled-components';

interface MessageBadgeProps {
  backgroundColor: string;
  opacity: number;
}

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
`;

export const MessageBadgeWrapper = styled.div<MessageBadgeProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  width: 5.7px;
  height: 5.7px;

  background: ${(props) => props.backgroundColor};
  border-radius: 50%;

  opacity: ${(props) => props.opacity};
`;

export const Text = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
`;
