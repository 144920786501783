import { MainLayout } from '@/components/layout';

import { initTranslation } from './translation';
import { Home } from '@/pages';

initTranslation();

const App = () => {
  return (
    <MainLayout>
      <Home />
    </MainLayout>
  );
};

export default App;
