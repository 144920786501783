import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 24px 0px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const PlaceholderText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #a7b4c8;
`;
