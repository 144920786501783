import styled from 'styled-components';

export const Toggler = styled.input``;

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 22px;
  height: 12px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: #467aff;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #467aff;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(9px);
    -ms-transform: translateX(9px);
    transform: translateX(9px);
  }

  .slider.round {
    border-radius: 8px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

export const Circle = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: 7px;
    width: 7px;
    left: 3px;
    bottom: 2.5px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;
