import styled from 'styled-components';

interface WithColor {
  color: 'white' | 'blue';
}

interface WrapperProps extends WithColor {
  disabled: boolean;
}

export const Wrapper = styled.button<WrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 20px;
  gap: 6px;
  transition: all 0.3s ease;

  background: ${(props) => (props.color === 'blue' ? '#467aff' : '#FFFFFF')};
  border: solid #e7e8e9;
  border-width: ${(props) => (props.color === 'blue' ? '0px' : '1px')};
  border-radius: 6px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Text = styled.span<WithColor>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: ${(props) => (props.color === 'blue' ? '#ffffff' : '#467AFF')};
`;
