import styled from 'styled-components';

import { DefaultScrollbarContainer } from '@/components/styles/containers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  width: 100%;
  position: relative;
`;

export const MessagesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const ChatboxContent = styled(DefaultScrollbarContainer)`
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: calc(425px - 48.5px);
  margin-top: auto;
  overflow: auto;
  padding: 13.5px;
  overflow: overlay;
`;

export const ScrollDownWrapper = styled.div`
  position: fixed;

  bottom: 70px;
  right: 50px;
  margin-bottom: 16px;
`;
